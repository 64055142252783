import { useTheme } from "@emotion/react";
import { FC, useEffect } from "react";
import { FTSTheme } from "../../App";
import { MainLayout } from "../../components/Layout";
import { NavbarNew } from "../../components/Navbar";
import { useStoreActions } from "../../hooks";
import "./Dashboard.css";
import DashboardContent from "./Templates/DashboardContent";
import useAppTitle from "../../hooks/useAppTitle";

const Dashboard: FC<{}> = (props) => {
  const theme = useTheme() as FTSTheme;

  const { setOpenProductDesignDialog, setOpenCountryDialog } = useStoreActions((actions) => actions.app);

  const { setCurrentProjectId, setCurrentDesignId, setProductDesigns, setUserDesignData, thunkGetProjectsSummary } = useStoreActions(
    (actions) => actions.designs
  );

  const appTitle = useAppTitle()

  useEffect(() => {
    setOpenProductDesignDialog(false);
    setOpenCountryDialog(false);
    setCurrentProjectId(null);
    setCurrentDesignId(null);
    setUserDesignData(null);
    thunkGetProjectsSummary();
    setProductDesigns([])
  }, []);

  return (
    <MainLayout.Root>
      <NavbarNew.Root>
        <div className="navbar__logoTitleContainer">
          <NavbarNew.Logo link="/" />
          <NavbarNew.Title title={appTitle} />
        </div>
        <NavbarNew.Links>
          <NavbarNew.Link
            translationKey="dashboard"
            path="/dashboard"
          />
          <NavbarNew.Link
            translationKey="myProjects"
            path="/projects"
          />
          <NavbarNew.Link
            translationKey="favoriteDesigns"
            path="/favoriteDesigns"
          />
          <div className="link__separator"></div>
          {/* <Link
            to="/checkout"
            className="shoppingCart__link"
          >
            <ShoppingCartIcon
              width={24}
              height={24}
            />
            <Typography variant="body1">{t("cart")}</Typography>
          </Link> */}
          <NavbarNew.User />
        </NavbarNew.Links>
      </NavbarNew.Root>

      <MainLayout.Content>
        <MainLayout.PageHeader pageHeaderTranslationKey={`${theme.name}.dashboard.welcome_message`} />
        <DashboardContent />
      </MainLayout.Content>
    </MainLayout.Root>
  );
};

export default Dashboard;
