import {
  Button, Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FavoriteDesignsPreview, { favoriteDesignsPreviewRef } from "../../../FavoriteDesigns/FavoriteDesignsPreview";
import { IProjectSummaryDto } from "../../../../interfaces/DTO/IProjectSummaryDto";
import { NUMBER_OF_SINGLE_COVER, PAGE_INITIAL_INDEX, ROWS_PER_PAGE_DIALOG } from "../../../../constants/NumberValues";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { FTSTheme } from "../../../../App";
import { IProductFavoriteDesignResponse } from "../../../../interfaces/Project/Response/ProductDesignResponse";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

interface IProps {
  favoriteDesigns: IProductFavoriteDesignResponse[] | null;
  handleClose: () => void;
  setChosenFavoriteDesignStyles: (design: any) => void;
}

interface IHeaderCell {
  id: string;
  label: string;
  width: string;
  sortable: boolean;
}

const FavoriteDesignsDialogTable: React.FC<IProps> = ({ favoriteDesigns, handleClose, setChosenFavoriteDesignStyles }) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;

  const [page, setPage] = useState(PAGE_INITIAL_INDEX);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DIALOG);
  const [sortedFavoriteDesigns, setSortedFavoriteDesigns] = useState<IProductFavoriteDesignResponse[]>(favoriteDesigns || []);
  const { favoriteDesignData } = useStoreState(state => state.designs.data);

  const { thunkGetFavoriteDesignById } = useStoreActions((actions) => actions.designs);

  const { setProductDesignPreview } = useStoreActions((actions) => actions.designs);
  const { setUserDesignDataFromProductDesignResponse } = useStoreActions((actions) => actions.designs);

  const [selectedDesignId, setSelectedDesignId] = useState<string | null>(null);

  useEffect(() => {
    favoriteDesigns && setSortedFavoriteDesigns(favoriteDesigns);
  }, [favoriteDesigns]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(PAGE_INITIAL_INDEX);
  };

  const headerCells: readonly IHeaderCell[] = [
    {
      id: "check",
      label: " ",
      width: "100px",
      sortable: false,
    },
    {
      id: "preview",
      label: "favoriteDesigns.table.header.preview",
      width: "",
      sortable: false,
    },
    {
      id: "description",
      label: "favoriteDesigns.table.header.description",
      width: "",
      sortable: false,
    }
  ];

  const handleOpenDetails = async (favoriteDesignId: string, numberOfCovers: number | undefined) => {
    let designToSet;

    if (favoriteDesignData?.favoriteDesignId === favoriteDesignId) {
      designToSet = favoriteDesignData;
    } else {
      try {
        designToSet = await thunkGetFavoriteDesignById(favoriteDesignId);
      } catch (error) {
        console.error("Failed to fetch design:", error);
        return;
      }
    }

    if (designToSet) {
      designToSet.numberOfCovers = numberOfCovers;
      setUserDesignDataFromProductDesignResponse(designToSet);
      setProductDesignPreview(designToSet);
      favoriteDesignsPreviewRef.current?.open();
    }
  };

  const handleChoice = (id: string) => {
    setSelectedDesignId(id);
  };

  const handleConfirm = async () => {
    if (selectedDesignId) {
      try {
        const fullFavoriteDesign = await thunkGetFavoriteDesignById(selectedDesignId as string);
        setChosenFavoriteDesignStyles(fullFavoriteDesign)
        handleClose();
      } catch (error) {
        console.error("Failed to fetch design:", error);
        return;
      }
    }
  }
  
  return (
    <>
      <TableContainer className="favoriteDesignsDialogTable__container">
        <Table className="favoriteDesignsDialogTable__table">
          <TableHead className={`projects__${ftsTheme.name}_table_head`}>
            <TableRow>
              {headerCells.map((headerCell: IHeaderCell) => {
                return (
                  <TableCell
                    className={`projects__table_header_cell ${headerCell.id === "name" ? "name" : ""}`}
                    width={headerCell.width}
                    key={headerCell.id}
                  >
                    <>{t(headerCell.label)}</>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!favoriteDesigns?.length && (
              <TableRow>
                <TableCell colSpan={5}>
                    <Typography className="favoriteDesignsDialogTable__emptyText">
                      {t("favoriteDesignsDialog_emptyList")}
                    </Typography>
                </TableCell>
              </TableRow>
            )}
            {sortedFavoriteDesigns
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((favoriteDesign) => {
                return (
                  <TableRow
                    key={favoriteDesign.favoriteDesignId}
                    onClick={() => handleChoice(favoriteDesign.favoriteDesignId)}
                    style={{ cursor: 'pointer', backgroundColor: selectedDesignId === favoriteDesign.favoriteDesignId ? '#ebebeb' : 'white' }}
                  >
                    <TableCell className="favoriteDesigns__table_row_cell_checkbox">
                      <FormControlLabel
                        value={favoriteDesign.favoriteDesignId}
                        control={<Radio checked={selectedDesignId === favoriteDesign.favoriteDesignId} />}
                        label={''}
                        onChange={() => handleChoice(favoriteDesign.favoriteDesignId)}
                      />
                    </TableCell>
                    <TableCell className="projects__table_row_cell">
                      <div
                        className="thumb"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDetails(favoriteDesign.favoriteDesignId, NUMBER_OF_SINGLE_COVER);
                        }}
                      >
                        {favoriteDesign?.coverImage && (
                          <img
                            height={'100%'}
                            width={'64px'}
                            src={favoriteDesign.coverImage}
                            alt={`${favoriteDesign.name}, ${t(`insertcolors.${favoriteDesign.colorCode}`)}`}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="projects__table_row_cell">
                      {`${favoriteDesign.name}, ${t(`insertcolors.${favoriteDesign.colorCode}`)}`}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", justifyContent: "space-between", padding: '28px 0px 0px' }}>
        {favoriteDesigns && (
          <TablePagination
            rowsPerPageOptions={[ROWS_PER_PAGE_DIALOG]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            component="div"
            count={favoriteDesigns.length}
            page={page}
            onPageChange={handleChangePage}
          />
        )}
        <div>
          <Button variant="outlined" size="medium" onClick={handleClose} sx={{ marginRight: '24px' }}>
            Cancel
          </Button>
          <Button variant="contained" size="medium" onClick={handleConfirm} disabled={selectedDesignId === null}>
            Confirm
          </Button>
        </div>
      </div>
      <FavoriteDesignsPreview displayPreviewTabs ref={favoriteDesignsPreviewRef} />
    </>
  );
};

export default FavoriteDesignsDialogTable
