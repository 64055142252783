import { Grid } from "@mui/material";
import TileConfiguration from "../TileConfiguration";
import StandardTile from "../StandardTile";
import { ITileButtonProps } from "../../../../../interfaces/ITileButtonProps";
import TilePreviewConfiguration from "../TilePreviewConfiguration";
import { ITileDimension } from "../../../../../interfaces/ITileDimension";
import { EFontPixelSize } from "../../../../../enum/EFontSize";
import { EIconPercentSize, EIconPixelSize } from "../../../../../enum/EIconSize";
import { isHorizontal, LimitChar } from "../limitChar";
import { useRef } from "react";
import { EInsertType } from "../../../../../enum/EInsertType";
import GridLine from "../../../Components/GridLine/GridLine";
import { EInsertDetailItemType } from "../../../../../interfaces/IInsertDetail";
import { useStoreActions } from "../../../../../hooks";
import { generateCoverHash } from "../utils";
import SliderLine from "../../../Components/SliderLine/SliderLine";
import { FrameStyleProps } from "../../../../../interfaces/IFrameAndCoverStyle";

const OneSliderOneRocker: React.FC<ITileButtonProps> = (props) => {
  const border = props.isPreview ? "tile-border-preview" : "tile-border";

  const tileSliderDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.STANDARD,
    textareaMaxHeight: 42,
    spaceBetweenIconAndTextArea: 4,
    isFunction: false
  };

  const tileControlDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.MEDIUM,
    textareaMaxHeight: 31,
    spaceBetweenIconAndTextArea: -2,
    isFunction: false
  };

  const tileFunctionDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_FUNCTION,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.LARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.MEDIUM,
    textareaMaxHeight: 39,
    isFunction: true,
  };

  const textFieldProps = LimitChar.get(props.designCode)!;

  const coverRef = useRef<HTMLDivElement>(null);
  const sliderTopRef = useRef<HTMLHRElement>(null);
  const sliderBottomRef = useRef<HTMLHRElement>(null);

  const coverHash = generateCoverHash(10);

  return (
    <>
      <div
        ref={coverRef}
        data-coverhash={coverHash}
        data-designcode={props.designCode}
      >
        <StandardTile
          designCode={props.designCode}
          direction="column"
        >
          <Grid
            className={`${border} container direction-column one-slider-one-rocker-margin-right ${props.insertDetail.isFlipped ? "isFlipped" : ""
              }`}
            container
            justifyContent="center"
            alignItems="center"
          >
            <SliderLine
              coverRef={coverRef}
              elementRef={sliderTopRef}
              className={`slider slider-top`}
              indexDetail="6"
              cover={props.cover}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
            />
            {props.isPreview ? <span >
              <TilePreviewConfiguration
                tileDimension={tileSliderDimension}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneSliderOneRocker
                    : textFieldProps.vertical.oneSliderOneRocker
                }
                tileName="oneSliderOneRocker"
                isRocker={false}
                cover={props.cover}
                insertDetail={props.insertDetail!}
                indexDetail="1"
              />
            </span> :
              <span >
                <TileConfiguration
                  isRocker={false}
                  tileType={EInsertType.Slider}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneSliderOneRocker
                      : textFieldProps.vertical.oneSliderOneRocker
                  }
                  tileName="oneSliderOneRocker"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileSliderDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="1"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>}
            <SliderLine
              coverRef={coverRef}
              elementRef={sliderBottomRef}
              className={`slider slider-bottom${props.insertDetail.items?.find(
                (item) => item.type === EInsertDetailItemType.Text && item.indexDetail == "1"
              )
                  ? "-with-canvas"
                  : ""
                }`}
              indexDetail="7"
              cover={props.cover}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
            />
          </Grid>

          <GridLine
            coverRef={coverRef}
            styleProps={{
              width: "1px",
              height: `calc(100% - ${FrameStyleProps.get(props.designCode)?.cover.padding} * 2)`,
              backgroundColor: props.insertDetail.insertColor?.secondaryPrintColor?.hexColor,
              position: 'absolute',
              left: 'calc(50% - 0.5px)',
            }}
            enable={props.insertDetail.hasGridLine}
            cover={props.cover}
            indexDetail="5"
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            verticalDegrees={[0, 180]}
            defaultOrientation="vertical"
          />
          <Grid
            className={`${border} tile-border-rocker one-slider-one-rocker-margin-left one-slider-one-rocker-margin-bottom printable-area-one-slider-one-rocker ${props.insertDetail.isFlipped ? "isFlipped" : ""
              }`}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              {props.isPreview ? <span >
                <TilePreviewConfiguration
                  tileDimension={tileControlDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneSliderOneRocker
                      : textFieldProps.vertical.oneSliderOneRocker
                  }
                  tileName="oneSliderOneRocker"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="2"
                />
              </span> :
                <span >
                  <TileConfiguration
                    isRocker={true}
                    textLabelSizes={
                      isHorizontal(props.insertDetail.rotate!)
                        ? textFieldProps.horizontal.oneSliderOneRocker
                        : textFieldProps.vertical.oneSliderOneRocker
                    }
                    tileName="oneSliderOneRocker"
                    isHorizontal={props.isHorizontal}
                    designCode={props.designCode}
                    tileDimension={tileControlDimension}
                    insertDetail={props.insertDetail}
                    setInsertDetail={props.setInsertDetail}
                    cover={props.cover}
                    indexDetail="2"
                    coverRef={coverRef}
                    canManageRef={props.canManageRef}
                  />
                </span>}
            </Grid>
          </Grid>

          <Grid
            className={`${border} tile-border-rocker one-slider-one-rocker-margin-left one-slider-one-rocker-margin-top one-slider-one-rocker-margin-bottom printable-area-one-slider-one-rocker ${props.insertDetail.isFlipped ? "isFlipped" : ""
              }`}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              {props.isPreview ? <span >
                <TilePreviewConfiguration
                  tileDimension={tileFunctionDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneSliderOneRocker.rocker!
                      : textFieldProps.vertical.oneSliderOneRocker.rocker!
                  }
                  tileName="oneSliderOneRocker"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="3"
                />
              </span> :
                <span >
                  <TileConfiguration
                    textLabelSizes={
                      isHorizontal(props.insertDetail.rotate!)
                        ? textFieldProps.horizontal.oneSliderOneRocker.rocker!
                        : textFieldProps.vertical.oneSliderOneRocker.rocker!
                    }
                    tileName="oneSliderOneRocker"
                    isRocker={true}
                    isHorizontal={props.isHorizontal}
                    designCode={props.designCode}
                    tileDimension={tileFunctionDimension}
                    insertDetail={props.insertDetail}
                    setInsertDetail={props.setInsertDetail}
                    cover={props.cover}
                    indexDetail="3"
                    coverRef={coverRef}
                    canManageRef={props.canManageRef}
                  />
                </span>}
            </Grid>
          </Grid>

          <Grid
            className={`${border} tile-border-rocker one-slider-one-rocker-margin-left one-slider-one-rocker-margin-top printable-area-one-slider-one-rocker ${props.insertDetail.isFlipped ? "isFlipped" : ""
              }`}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              {props.isPreview ? <span >
                <TilePreviewConfiguration
                  tileDimension={tileControlDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneSliderOneRocker
                      : textFieldProps.vertical.oneSliderOneRocker
                  }
                  tileName="oneSliderOneRocker"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="4"
                />
              </span> :
                <span >
                  <TileConfiguration
                    textLabelSizes={
                      isHorizontal(props.insertDetail.rotate!)
                        ? textFieldProps.horizontal.oneSliderOneRocker
                        : textFieldProps.vertical.oneSliderOneRocker
                    }
                    tileName="oneSliderOneRocker"
                    isRocker={true}
                    isHorizontal={props.isHorizontal}
                    designCode={props.designCode}
                    tileDimension={tileControlDimension}
                    insertDetail={props.insertDetail}
                    setInsertDetail={props.setInsertDetail}
                    cover={props.cover}
                    indexDetail="4"
                    coverRef={coverRef}
                    canManageRef={props.canManageRef}
                  />
                </span>}
            </Grid>
          </Grid>
        </StandardTile>
      </div>
    </>
  );
};

export default OneSliderOneRocker;
