import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { TextField, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExternalLayout from "../../../components/Layout/ExternalLayout";
import Spinner from "../../../components/Spinner/Spinner";
import { EDesignStatus } from "../../../enum/EDesignStatus";
import { useStoreActions, useStoreState } from "../../../hooks";
import { IInsertDetail } from "../../../interfaces/IInsertDetail";
import Cover from "../Components/Cover/Cover";
import Frame from "../Components/Frame/Frame";
import LayoutConfiguration from "../InsertConfiguration/Tiles/LayoutConfiguration";
import "./DesignPreview.css";
import "./DesignPreview-1280.css";
import { useTranslation } from "react-i18next";
import { IProjectSummaryDto } from "../../../interfaces/DTO/IProjectSummaryDto";
import AddProjectButton from "./AddProjectButton";
import MainSummaryTitle from "./MainSummaryTitle";
import MainSummaryCoverInfoContainer from "./MainSummaryCoverInfoContainer";
import { FTSTheme } from "../../../App";
import { toBlob } from "html-to-image";
import { ICoverImageDto } from "../../../interfaces/DTO/ICoverImageDto";
import useAppTitle from "../../../hooks/useAppTitle";
import EditDialog, { EditButton, editDialogRef } from "../DesignSummary/Edit/EditDialog";
import { IFoundFavoriteDesigns } from "../../../interfaces/IFoundFavoriteDesigns";

type DesignPreviewState = {
  isDesignSelected?: boolean;
};

const DesignPreview: FC<{}> = () => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;
  const coverRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as DesignPreviewState | null;
  const { productDesigns, projectsSummary } = useStoreState((state) => state.designs.data);
  const { userDesignData, currentProjectId } = useStoreState((state) => state.designs.configuration);
  const { setUserDesignData, thunkAutoSave, thunkUploadCoverAzStorage } = useStoreActions((actions) => actions.designs);
  const { setProductDesigns } = useStoreActions((actions) => actions.designs);
  const { thunkCheckFavoriteDesigns } = useStoreActions((actions) => actions.designs);

  const [currentProject, setCurrentProject] = useState<IProjectSummaryDto | null>(null);
  const [loadingAddProject, setLoadingAddProject] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);
  const appTitle = useAppTitle();
  const [projectName, setProjectName] = useState<string>("");
  const [roomText, setRoomText] = useState<string>(userDesignData?.userCustomDesign?.room || "");
  const [locationText, setLocationText] = useState<string>(userDesignData?.userCustomDesign?.location || "");
  const [checkedFavoriteDesigns, setCheckedFavoriteDesigns] = useState<IFoundFavoriteDesigns[]>([]);

  const inputLimit = 30;
  
  const checkInitialRun = useRef(false);

  useEffect(() => {
    if (checkInitialRun.current) {
      return;
    }
    checkInitialRun.current = true;

    const checkInitial = async () => {
      try {
        updateProductDesigns();
        const response = await thunkCheckFavoriteDesigns();
        setCheckedFavoriteDesigns(response)
      } catch (error) {
        console.error('Error during thunk execution:', error);
      }
    };

    checkInitial();
  }, [checkedFavoriteDesigns]);

  useEffect(() => {
    if (state == null) {
      navigate("/dashboard", { replace: true });
    }

    const newCurrentProject = projectsSummary?.find((x) => x.id === currentProjectId)!;
    setCurrentProject(newCurrentProject);
    setProjectName(newCurrentProject?.name)
  }, []);

  const updateProductDesigns = () => {
    const userCustomDesign = userDesignData?.userCustomDesign;
    const userCustomDesignId = userCustomDesign?.id;
    const userInsertDetails = userCustomDesign?.insertDetails ?? [];

    const matchingProductDesign = productDesigns.find(
      (productDesign) => productDesign.id === userCustomDesignId
    );

    const userInsertDetailsLength = userInsertDetails.length;
    const productInsertDetailsLength = matchingProductDesign?.insertDetails?.length ?? 0;

    if (userInsertDetailsLength > productInsertDetailsLength) {
      const updatedProductDesigns = productDesigns.map((productDesign) =>
        productDesign.id === userCustomDesignId
          ? { ...productDesign, insertDetails: userInsertDetails }
          : productDesign
      );

      setProductDesigns(updatedProductDesigns);
    }
  };

  const handleRoomTextChange = (event) => {
    const newText = event.target.value
    if (newText.length <= inputLimit) { setRoomText(newText) }
  }

  const handleLocationTextChange = (event) => {
    const newText = event.target.value
    if (newText.length <= inputLimit) { setLocationText(newText) }
  }

  const handleGoToInsertConfiguration = async (selectedCoverNumber: number) => {
    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            pathname: "/insert-configuration",
          },
          room: roomText,
          location: locationText
        },
      },
    };
    setUserDesignData(newValues);
    thunkAutoSave(newValues);

    navigate("/insert-configuration", {
      state: {
        selectedCoverNumber: selectedCoverNumber,
        isDesignSelected: true,
      },
    });
  };

  const handleBackToInsertConfiguration = async () => {
    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            pathname: "/insert-configuration",
          },
        },
      },
    };

    setUserDesignData(newValues);
    thunkAutoSave(newValues);

    navigate("/insert-configuration", {
      state: {
        selectedCoverNumber: 0,
        isDesignSelected: true,
      },
    });
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const createCoverBlobs = async () => {
    for (let index = 0; index < coverRefs.current.length; index++) {
      const ref = coverRefs.current[index];

      const blob = await toBlob(ref, { cacheBust: false });

      var coverBase64 = await blobToBase64(blob);
      var coverElement = ref as Element;
      var coverDesignId = coverElement.getAttribute("data-coverdesignid")!;

      let item: ICoverImageDto = {
        coverImage: coverBase64 as string,
        coverDesignId: coverDesignId,
      };

      await thunkUploadCoverAzStorage([item]);
    }
  };

  const handleBackToFrameConfiguration = async () => {
    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            pathname: "/frame-configuration",
          },
        },
      },
    };

    setUserDesignData(newValues);
    thunkAutoSave(newValues);

    navigate("/frame-configuration", {
      state: {
        isDesignSelected: true,
      },
    });
  };

  const handleGoToSummary = async () => {
    setLoadingAddProject(true);

    await createCoverBlobs();
    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            pathname: "/design-summary",
            designStatus: EDesignStatus.Completed,
          },
          room: roomText,
          location: locationText
        },
      },
    };
    setUserDesignData(newValues);

    await thunkAutoSave(newValues);

    await navigate("/design-summary", {
      state: {
        isDesignSelected: true,
        isAddingProject: true,
      },
    });
  };

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      pageHeaderTextTranslationKey="welcomeToBuschJaegerCustomisationCenter"
      navbarLogoLink="/"
      navbarMainTitle={appTitle}
      navbarBackGroundColor="#fff"
      bodyBackGroundColor="#fff"
    >
      {(isLoading && <Spinner />) || (
        <>
          <div className="designPreview">
            <div className="designPreview__main">
              <div className="designPreview__headerContainer">
                <div className="designPreview__headerContainerButtons">
                  <Button
                    className="designPreview__backToSwitchSelectionBtn"
                    variant="text"
                    onClick={handleBackToInsertConfiguration}
                    startIcon={<ChevronLeftOutlinedIcon sx={{ width: "28px", height: "28px" }} />}
                  >
                    <span>{t("design_preview.button.back_editing_cover")}</span>
                  </Button>
                  <Typography
                    variant="body1"
                    fontSize="16px"
                    fontWeight={400}
                    color="#696969"
                  >
                    {t("design_preview.label.project_name")}
                    <span style={{ color: "#000" }}> {projectName}</span>
                    <EditButton editDialogRef={editDialogRef} />
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="designPreview__mainContainer">
            <div className="designPreview__mainPreview">
              <div className={`designPreview__mainPreviewContent`}>
                <div
                  className={
                    userDesignData?.userCustomDesign.isHorizontal
                      ? `designPreview__mainPreviewImage--horizontal`
                      : `designPreview__mainPreviewImage--vertical`
                  }
                >
                  <Frame
                    numberOfSwitches={userDesignData?.numberOfSwitches!}
                    isHorizontal={userDesignData?.userCustomDesign.isHorizontal!}
                    design={userDesignData?.design!}
                    frameColor={userDesignData?.userCustomDesign.frameColor!}
                    enableSelection={false}
                    enableTransition={false}
                  >
                    {userDesignData?.userCustomDesign.insertDetails.map((detail, coverIndex) => (
                      <Cover
                        key={`cover-${coverIndex}`}
                        cover={coverIndex}
                        designCode={userDesignData?.design?.code!}
                        isHorizontal={userDesignData.userCustomDesign.isHorizontal!}
                        insertDetail={detail}
                        coverRefs={coverRefs}
                      >
                        <LayoutConfiguration
                          designCode={userDesignData?.design?.code!}
                          isHorizontal={userDesignData.userCustomDesign.isHorizontal!}
                          key={`layout-configuration-preview-${coverIndex}`}
                          insertDetail={detail}
                          setInsertDetail={(detail: IInsertDetail) => { }} //change property optional
                          cover={coverIndex}
                          insertType={detail.insertType?.name}
                          isPreview={true}
                          canManageRef={false}
                        />
                      </Cover>
                    ))}
                  </Frame>
                </div>
              </div>
            </div>

            <div className={`designPreview__mainSummary`}>
              <MainSummaryTitle />

              <div className={`designPreview__mainSummaryDivider_${ftsTheme.name}`} />

              <div className={`designPreview__mainGeneralCustomizationTitle_${ftsTheme.name}`}>
                <Typography
                  variant="h4"
                  fontSize="21px"
                  fontWeight={700}
                >
                  {t("design_preview.main_summary.title")}
                </Typography>
                <Button
                  className="designPreview_mainGeneralCustomizationTitleEditBtn"
                  variant="text"
                  onClick={handleBackToFrameConfiguration}
                >
                  {t("design_preview.button.edit")}
                </Button>
              </div>

              <div style={{ display: "flex" }}>
                <Typography
                  mt={2}
                  variant="body1"
                  fontSize="16px"
                  fontWeight={400}
                >
                  {t("design_preview.label.design_range")}
                </Typography>
                <Typography
                  mt={2}
                  ml={1}
                  variant="body1"
                  fontSize="16px"
                  fontWeight={300}
                >
                  {userDesignData?.design?.name}
                </Typography>
              </div>

              <div className="designPreview__mainSummaryFrameColor">
                <Typography
                  mt={2}
                  variant="body1"
                  fontSize="16px"
                  fontWeight={400}
                >
                  {t("design_preview.label.frame_color")}
                </Typography>
                <Typography
                  mt={2}
                  variant="body1"
                  fontSize="16px"
                  fontWeight={300}
                >
                  {t(`framecolors.${userDesignData?.userCustomDesign.frameColor?.code}`)}
                </Typography>
                <div
                  style={{ backgroundColor: userDesignData?.userCustomDesign.frameColor?.hexColor }}
                  className="designPreview__mainSummaryFrameColorItem"
                ></div>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <Typography
                  mt={2}
                  variant="body1"
                  fontSize="16px"
                  fontWeight={300}

                >
                  {t("DesignSummary.room")}:
                </Typography>
                <TextField
                  size="small"
                  value={roomText}
                  onChange={handleRoomTextChange}
                />
              </div>
              <div style={{ display: "flex", gap: "5px", marginBottom: "12px" }}>
                <Typography
                  mt={2}
                  variant="body1"
                  fontSize="16px"
                  fontWeight={300}
                >
                  {t("DesignSummary.location")}: </Typography>
                <TextField
                  style={{ marginLeft: "4px" }}
                  size="small"
                  value={locationText}
                  onChange={handleLocationTextChange}
                />
              </div>
              <div className="designPreview__mainSummaryCoversContainer">
                {userDesignData?.userCustomDesign.insertDetails
                  .filter((detail) => detail !== null)
                  .map((detail, index) => (
                    <MainSummaryCoverInfoContainer
                      id={detail?.id}
                      index={index}
                      insertTypeName={detail.insertType?.name}
                      insertColorCode={detail?.insertColor?.code}
                      handleGoToInsertConfiguration={handleGoToInsertConfiguration}
                      detail={detail}
                      checkedFavoriteDesigns={checkedFavoriteDesigns}
                      setCheckedFavoriteDesigns={setCheckedFavoriteDesigns}
                    />
                  ))}
              </div>
              <div className="designPreview__mainSummaryButtons">
                <AddProjectButton
                  loading={loadingAddProject}
                  handleGoToSummary={handleGoToSummary}
                />
              </div>
            </div>
            <EditDialog
              ref={editDialogRef}
              name={projectName}
              projectId={currentProjectId!}
              setProjectName={setProjectName}
            />
          </div>
        </>
      )}
    </ExternalLayout>
  );
};

export default DesignPreview;
