import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useRef, useState } from "react";
import { Checkbox, FormControlLabel, useTheme } from "@mui/material";
import { IProductDesignResponse } from "../../interfaces/Project/Response/ProductDesignResponse";
import { useStoreActions, useStoreState } from "../../hooks";
import { useTranslation } from "react-i18next";
import { IInsertDetail } from "../../interfaces/IInsertDetail";
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { toPng } from "html-to-image";
import Frame from "../../pages/Configurator/Components/Frame/Frame";
import Cover from "../../pages/Configurator/Components/Cover/Cover";
import LayoutConfiguration from "../../pages/Configurator/InsertConfiguration/Tiles/LayoutConfiguration";
import { INSERT_DETAILS_SINGLE_ELEMENT, NUMBER_OF_SINGLE_SWITCH } from "../../constants/NumberValues";
import { IFoundFavoriteDesigns } from "../../interfaces/IFoundFavoriteDesigns";

interface Props {
  item: IInsertDetail;
  checkedFavoriteDesigns: IFoundFavoriteDesigns[];
  setCheckedFavoriteDesigns: React.Dispatch<React.SetStateAction<IFoundFavoriteDesigns[]>>;
  numberOfCover: number;
}

const FavoriteDesignsCheckbox: React.FC<Props> = ({ item, checkedFavoriteDesigns, setCheckedFavoriteDesigns, numberOfCover }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentUser } = useStoreState((state) => state.auth);
  const { productDesigns } = useStoreState((state) => state.designs.data);
  const { thunkAddFavoriteDesign, thunkDeleteFavoriteDesignById } = useStoreActions((actions) => actions.designs);
  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const [coverImage, setCoverImage] = useState<string>('');
  const [favoriteCoverWithFrame, setFavoriteCoverWithFrame] = useState<IProductDesignResponse>();
  const [newFavoriteDesign, setNewFavoriteDesign] = useState<IProductDesignResponse>();
  const thumbRef = useRef<HTMLDivElement | null>(null);

  const isChecked = checkedFavoriteDesigns?.some(
    (design) => design?.insertId === item.id && design?.favoriteId
  ) || false;


  useEffect(() => {
    const generateImage = () => {
      if (thumbRef.current === null) {
        return;
      }

      toPng(thumbRef.current)
        .then((dataUrl) => {
          setCoverImage(dataUrl);
        })
        .catch((error) => {
          console.error('Error generating image:', error);
        });
    };

    generateImage();
  }, [thumbRef.current, favoriteCoverWithFrame]);

  useEffect(() => {
    const updateFavoriteDesigns = async () => {
      if (newFavoriteDesign && coverImage) {
        try {
          await thunkAddFavoriteDesign({ ...newFavoriteDesign, coverImage: coverImage });
        } catch (error) {
          // TODO: remove from checked arr if sth went wrong
          console.error('Error updating favorite designs:', error);
        }
      }
    };

    updateFavoriteDesigns();
  }, [coverImage, newFavoriteDesign, thunkAddFavoriteDesign]);


  const handleMarkAsFavoriteDesign = (id: string, selectedCoverNumber: number) => {
    let productDesign;
    productDesign = productDesigns.find(productDesign =>
      productDesign.insertDetails.some(insertDetail => insertDetail.id === id)
    );

    if (!productDesign) {
      const incompleteProductDesign = productDesigns.find(productDesign => productDesign.id === userDesignData?.userCustomDesign.id)
      productDesign = {
        ...incompleteProductDesign, 
        insertDetails: userDesignData?.userCustomDesign.insertDetails 
      };

    };
    if (!checkedFavoriteDesigns) return;

    const favoriteCover = productDesign.insertDetails[selectedCoverNumber];
    const fullFavoriteCover = { ...productDesign, insertDetails: [favoriteCover] };

    setFavoriteCoverWithFrame(fullFavoriteCover);

    const favId = uuidv4();
    const newFavoriteDesign: IProductDesignResponse = {
      ...productDesign,
      insertDetails: [{ ...favoriteCover }],
      favoriteDesignId: favId,
      comment: "",
      project: {
        ...productDesign.project,
        countryId: currentUser?.countryId,
      },
    };

  setNewFavoriteDesign(newFavoriteDesign);
  if (checkedFavoriteDesigns.length && newFavoriteDesign) {
    setCheckedFavoriteDesigns((prev) =>
      prev.map((checkedFavoriteDesign) => {
        if (checkedFavoriteDesign.insertId === favoriteCover.id) {
          return { ...checkedFavoriteDesign, favoriteId: favId as string };
        }
        return checkedFavoriteDesign;
      }),
    );
  } else {
    const newCheckedFavoriteDesigns = Array(productDesign.insertDetails.length).fill(null);
    newCheckedFavoriteDesigns[numberOfCover] = favId;
    setCheckedFavoriteDesigns(newCheckedFavoriteDesigns);
  }
  };

  const handleCheckboxChange = async () => {
    if (isChecked) {
      const designToDelete = checkedFavoriteDesigns.find(
        (checkedFavoriteDesign) => checkedFavoriteDesign.insertId === item.id
      );
      
      if (designToDelete) {
        await thunkDeleteFavoriteDesignById(designToDelete?.favoriteId as string);

        setCheckedFavoriteDesigns((prev) =>
          prev.map((checkedFavoriteDesign) => {
            if (checkedFavoriteDesign.insertId === designToDelete?.insertId) {
              return { ...checkedFavoriteDesign, favoriteId: null };
            }
            return checkedFavoriteDesign;
          }),
        );
      }
    } else {
      handleMarkAsFavoriteDesign(item.id as string, numberOfCover);
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name="pdf"
            checked={isChecked}
            onChange={handleCheckboxChange}
            icon={<FavoriteBorder />}
            checkedIcon={<Favorite />}
            sx={{
              color: '#000',
              '&.Mui-checked': {
                color: theme.palette.primary.main,
              },
            }}
          />
        }
        label={t("design_preview.button.add_favorite_design")}
      />
      {favoriteCoverWithFrame && (
        <div className="designPreview__favoriteDesign-hiddenContainer">
          <div className="designPreview__favoriteDesign-hiddenDiv">
            <div ref={thumbRef}>
              <div
                className={
                  userDesignData?.userCustomDesign.isHorizontal
                    ? `designPreview__mainPreviewImage--horizontal`
                    : `designPreview__mainPreviewImage--vertical`
                }
              >
                <Frame
                  numberOfSwitches={NUMBER_OF_SINGLE_SWITCH}
                  isHorizontal={userDesignData?.userCustomDesign.isHorizontal!}
                  design={userDesignData?.design!}
                  frameColor={userDesignData?.userCustomDesign.frameColor!}
                  enableSelection={false}
                  enableTransition={false}
                >
                  <Cover
                    key={`cover-${INSERT_DETAILS_SINGLE_ELEMENT}`}
                    cover={INSERT_DETAILS_SINGLE_ELEMENT}
                    designCode={userDesignData?.design?.code!}
                    isHorizontal={userDesignData?.userCustomDesign.isHorizontal!}
                    insertDetail={favoriteCoverWithFrame?.insertDetails[INSERT_DETAILS_SINGLE_ELEMENT] as IInsertDetail}
                  >
                    <LayoutConfiguration
                      designCode={userDesignData?.design?.code!}
                      isHorizontal={userDesignData?.userCustomDesign.isHorizontal!}
                      key={`layout-configuration-preview-${INSERT_DETAILS_SINGLE_ELEMENT}`}
                      insertDetail={favoriteCoverWithFrame?.insertDetails[INSERT_DETAILS_SINGLE_ELEMENT] as IInsertDetail}
                      setInsertDetail={(detail: IInsertDetail) => {}}
                      cover={INSERT_DETAILS_SINGLE_ELEMENT}
                      insertType={favoriteCoverWithFrame?.insertDetails[INSERT_DETAILS_SINGLE_ELEMENT].insertType?.name}
                      isPreview={true}
                      canManageRef={false}
                    />
                  </Cover>
                </Frame>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FavoriteDesignsCheckbox;
