import React, { createRef, forwardRef, useImperativeHandle, useState } from "react";
import { Dialog, DialogContent, IconButton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useStoreActions, useStoreState } from "../../hooks";
import CloseIcon from "../Configurator/Icons/close.svg";
import "./FavoriteDesignsPreview.css";

import { FTSTheme } from "../../App";
import DesignPreviewTabs from "../Configurator/DesignPreview/DesignPreviewTabs";
import { IDialogHandle } from "../../interfaces/IDialogHandle";
import { NUMBER_OF_SINGLE_SWITCH } from "../../constants/NumberValues";

export const favoriteDesignsPreviewRef = createRef<IDialogHandle>();

interface IFavoriteDesignsPreviewProps {
  displayPreviewTabs?: boolean;
}

const FavoriteDesignsPreview = forwardRef<IDialogHandle, IFavoriteDesignsPreviewProps>(
  ({ displayPreviewTabs }, ref) => {
    const { t } = useTranslation();
    const ftsTheme = useTheme() as FTSTheme;
    const { userDesignData } = useStoreState((state) => state.designs.configuration);
    const { setProductDesignPreview } = useStoreActions((actions) => actions.designs);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setProductDesignPreview(null);
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({ open: handleOpen, close: handleClose }));

    return (
      <Dialog onClose={handleClose} fullWidth open={open} className="favoriteDesignsPreviewDialog">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="primary"
          className="closeIcon"
        >
          <ReactSVG
            src={CloseIcon}
            beforeInjection={(svg) => {
              svg.setAttribute("class", "svgIcon");
              svg.style.fill = ftsTheme.icon.color;
            }}
          />
        </IconButton>
        <DialogContent>
          <Typography variant="h2" fontSize="24px" fontWeight={700} mb={2}>
            {userDesignData?.numberOfSwitches === NUMBER_OF_SINGLE_SWITCH
              ? t("design_preview_dialog_preview_title")
              : t("design_preview_dialog.set_preview_title")}
          </Typography>
          {!userDesignData ? (
            <p>{t("DesignSummaryDialog_NoContent")}</p>
          ) : (
            <div className="favoriteDesignsPreviewContent">
              <div
                className={`favoriteDesignsPreviewBackground ${
                  userDesignData.userCustomDesign.isHorizontal
                    ? "favoriteDesignsPreview__mainPreview--horizontal-dialog"
                    : "favoriteDesignsPreview__mainPreview--vertical-dialog"
                }`}
              >
                <img
                  src={userDesignData.userCustomDesign.coverImage}
                  alt={`${userDesignData?.design?.name}, ${t(
                    `insertcolors.${userDesignData?.userCustomDesign?.insertDetails?.[0]?.insertColor?.code}`
                  )}`}
                  width="100%"
                />
              </div>
            </div>
          )}
          {displayPreviewTabs && <DesignPreviewTabs />}
        </DialogContent>
      </Dialog>
    );
  }
);

export default FavoriteDesignsPreview;
