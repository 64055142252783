import { useTheme } from "@emotion/react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import React, { ForwardRefRenderFunction, createRef, forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { FTSTheme } from "../../../../App";
import { IDialogHandle } from "../../../../interfaces/IDialogHandle";
import CloseIcon from "../../Icons/close.svg";
import FavoriteDesignsDialogTable from "./FavoriteDesignsDialogTable";
import { IProductFavoriteDesignResponse } from "../../../../interfaces/Project/Response/ProductDesignResponse";
import { useStoreActions, useStoreState } from "../../../../hooks";
import DialogTitle from "@mui/material/DialogTitle";

export const favoriteDesignsDialogRef = createRef<IDialogHandle>();

interface IEmptyCoverDialog {
  setChosenFavoriteDesignStyles: (design: any) => void;
}

const FavoriteDesignsDialog: ForwardRefRenderFunction<IDialogHandle, IEmptyCoverDialog> = ({ setChosenFavoriteDesignStyles }, ref) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;
  const { thunkGetFilteredFavoriteDesign } = useStoreActions((actions) => actions.designs);
  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const [open, setOpen] = useState(false);
  const [favoriteDesignsList, setFavoriteDesignsList] = useState<IProductFavoriteDesignResponse[]>([]);


  const fetchFilteredFavoriteDesigns = async () => {
    try {
      if (userDesignData) {
        const frameColorId = userDesignData?.userCustomDesign?.frameColor?.id as string;
        const designId = userDesignData?.design?.id as string;
        const favDesigns = await thunkGetFilteredFavoriteDesign({ frameColorId, designId });
        setFavoriteDesignsList(favDesigns);
      }
    } catch (error) {
      console.error("Failed to fetch favorite designs:", error);
    }
  };


  useEffect(() => {
    fetchFilteredFavoriteDesigns();
  }, []);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Dialog
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "50%",
            height: '624px'
          },
        },
      }}
      fullWidth={true}
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        color="primary"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          width: 35,
          height: 35,
        }}
      >
        <ReactSVG
          src={CloseIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `fill: ${ftsTheme.icon.color};`
            );
          }}
          className="favoriteDesignsDialog__closeIcon"
        />
      </IconButton>
      <DialogTitle className="dialog-title-container">
        <div className="favoriteDesigns__dialog-title">
          <span>{t("favoriteDesignsDialog.title")}</span>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <FavoriteDesignsDialogTable
          favoriteDesigns={favoriteDesignsList}
          handleClose={handleClose}
          setChosenFavoriteDesignStyles={setChosenFavoriteDesignStyles}
        />
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(FavoriteDesignsDialog);