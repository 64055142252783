import IconButton from "@mui/material/IconButton";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreActions } from "../../hooks";
import { IProductDesignResponse } from "../../interfaces/Project/Response/ProductDesignResponse";
import DotsMenuFavDesigns from "./DotsMenuFavDesigns";
import ConfiguratorWelcomeDialog, {
  configuratorWelcomeDialogRef
} from "../../pages/Configurator/Components/WelcomeModal/ConfiguratorDialog/ConfiguratorWelcomeDialog";
import { useStoreState } from "../../hooks";

interface IThreeDotsMenuProps {
  favoriteDesignId:  string;
}

const ThreeDotsMenuFavDesigns: FC<IThreeDotsMenuProps> = ({ favoriteDesignId }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [chosenDesign, setChosenDesign] = useState<IProductDesignResponse | null>(null);
  const { thunkOpenConfirm, thunkOpenSnackBar, thunkCloseConfirm } = useStoreActions((actions) => actions.app);
  const { thunkGetFavoriteDesignById, thunkDeleteFavoriteDesignById } = useStoreActions((actions) => actions.designs);
  const { favoriteDesigns, favoriteDesignData } = useStoreState(state => state.designs.data);
  const { setFavoriteDesigns } = useStoreActions((actions) => actions.designs);

  const handleMoreOptionsClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl === null) return;

    const fetchDesign = async () => {
      const isSameFavDesign = favoriteDesignData?.favoriteDesignId === favoriteDesignId;

      if (isSameFavDesign) {
        setChosenDesign(favoriteDesignData);
      } else {
        try {
          const fullFavDesign = await thunkGetFavoriteDesignById(favoriteDesignId);
          setChosenDesign(fullFavDesign as IProductDesignResponse);
        } catch (error) {
          console.error("Failed to fetch design:", error);
        }
      }
    };


    fetchDesign();
  }, [anchorEl]);

  
  const  handleDeleteFavoriteDesign = () => {
    thunkOpenConfirm({
      title: t("favoriteDesigns.confirmDialog.title"),
      message: t("favoriteDesigns.confirmDialog.message"),
      onDismiss: {
        text: t("favoriteDesigns.confirmDialog.dismissBtn"),
      },
      onConfirm: {
        text: t("favoriteDesigns.confirmDialog.confirmBtn"),
        onClick: () => handleSubmitDelete(chosenDesign?.favoriteDesignId as string),
      },
      useLoading: true,
    });
  }

  const handleSubmitDelete = async (id: string) => {
    await thunkDeleteFavoriteDesignById(id)
    const updatedFavList = favoriteDesigns.filter(design => design.favoriteDesignId !== id)
    setFavoriteDesigns(updatedFavList)
      thunkOpenSnackBar({
        message: t("favoriteDesigns.confirmDialog.successMessage").replace("[DESIGN_NAME]", id),
        severity: "success",
      });

      thunkCloseConfirm();
      return;
  };
  
  const handleCreateWithDesign = () => {
    configuratorWelcomeDialogRef.current?.open();
  }

  return (
    <>
      <IconButton onClick={(e) => handleMoreOptionsClick(e)}>
        <MoreVertOutlinedIcon sx={{ color: "#000" }} />
      </IconButton>

      <DotsMenuFavDesigns
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleDeleteFavoriteDesign={handleDeleteFavoriteDesign}
        handleCreateWithDesign={handleCreateWithDesign}
      />
      <ConfiguratorWelcomeDialog ref={configuratorWelcomeDialogRef} favoriteDesign={chosenDesign as IProductDesignResponse} />
  </>
  );
};

export default ThreeDotsMenuFavDesigns;
