import { Grid } from "@mui/material";
import React, { createRef, useEffect, useMemo, useRef } from "react";
import { EFontPixelSize } from "../../../../../enum/EFontSize";
import { EIconPercentSize, EIconPixelSize } from "../../../../../enum/EIconSize";
import { ITileButtonProps } from "../../../../../interfaces/ITileButtonProps";
import { ITileDimension } from "../../../../../interfaces/ITileDimension";
import { isHorizontal, LimitChar } from "../limitChar";
import StandardTile from "../StandardTile";
import TileConfiguration from "../TileConfiguration";
import TilePreviewConfiguration from "../TilePreviewConfiguration";

const Rocker: React.FC<ITileButtonProps> = (props) => {
  const border = props.isPreview ? "tile-border-preview" : "tile-border";

  const tileControlDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.MEDIUM,
    textareaMaxHeight: 42,
    spaceBetweenIconAndTextArea: -2,
    isFunction: false
  };

  const tileFunctionDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_FUNCTION,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.LARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.MEDIUM,
    textareaMaxHeight: 42,
    isFunction: true,
    spaceBetweenIconAndTextArea: 2,
  };

  const textFieldProps = LimitChar.get(props.designCode)!;
  const coverRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={coverRef}
        data-designcode={props.designCode}
      >
        <StandardTile designCode={props.designCode}>
          <Grid
            className={`${border} rocker-margin-bottom printable-area-rocker`}
            container
            item
            alignItems="center"
            justifyContent="center"
          >
            {props.isPreview ? <span>
              <TilePreviewConfiguration
                tileDimension={tileControlDimension}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButton
                    : textFieldProps.vertical.oneButton
                }
                tileName="rocker"
                isRocker={true}
                cover={props.cover}
                insertDetail={props.insertDetail!}
                indexDetail="1"
              />
            </span> :
              <span>
                <TileConfiguration
                  isRocker={true}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.rocker
                      : textFieldProps.vertical.rocker
                  }
                  tileName="rocker"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileControlDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="1"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>}
          </Grid>
          <Grid
            className={`${border} rocker-margin-bottom rocker-margin-top printable-area-rocker`}
            container
            item
            alignItems="center"
            justifyContent="center"
          >
            {props.isPreview ? <span>
              <TilePreviewConfiguration
                tileDimension={tileFunctionDimension}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButton
                    : textFieldProps.vertical.oneButton
                }
                tileName="rocker"
                isRocker={true}
                cover={props.cover}
                insertDetail={props.insertDetail!}
                indexDetail="2"
              />
            </span> :
              <span>
                <TileConfiguration
                  isRocker={true}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.rocker.rocker!
                      : textFieldProps.vertical.rocker.rocker!
                  }
                  tileName="rocker"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileFunctionDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="2"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>}
          </Grid>
          <Grid
            className={`${border} rocker-margin-top printable-area-rocker`}
            container
            item
            alignItems="center"
            justifyContent="center"
          >
            {props.isPreview ? <span>
              <TilePreviewConfiguration
                tileDimension={tileControlDimension}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButton
                    : textFieldProps.vertical.oneButton
                }
                tileName="rocker"
                isRocker={true}
                cover={props.cover}
                insertDetail={props.insertDetail!}
                indexDetail="3"
              />
            </span> :
              <span>
                <TileConfiguration
                  isRocker={true}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.rocker
                      : textFieldProps.vertical.rocker
                  }
                  tileName="rocker"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileControlDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="3"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>}
          </Grid>
        </StandardTile>
      </div>
    </>
  );
};

export default Rocker;
