import { Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { createRef, forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "../../../../../hooks";
import { IDialogHandle } from "../../../../../interfaces/IDialogHandle";
import { IProjectCreateRequest } from "../../../../../interfaces/Project/Request/IProjectCreateRequest";
import { Status } from "../../../../../models/status";
import "./ConfiguratorWelcomeDialog.css";
import ConfirmButton from "./ConfirmButton";
import BackButton from "./BackButton";
import { ECompany } from "../../../../../enum/ECompany";
import { IInsertDetailRequest } from "../../../../../interfaces/IInsertDetail";
import { IProductDesignResponse } from "../../../../../interfaces/Project/Response/ProductDesignResponse";
import { useNavigate } from "react-router-dom";
import { BJE } from "../../../../../constants/Theme";
import { FAVORITE_DESIGNS_PATH } from "../../../../../constants/Paths";

interface IConfiguratorWelcomeDialogInputProps {
   favoriteDesign?: IProductDesignResponse | null;
}

export const configuratorWelcomeDialogRef = createRef<IDialogHandle>();

const ConfiguratorWelcomeDialog: ForwardRefRenderFunction<IDialogHandle, IConfiguratorWelcomeDialogInputProps> = (
  { favoriteDesign },
  ref,
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentPath = window.location.pathname;

  const [projectName, setProjectName] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);
  const { thunkCreateProject, thunkAutoSave, setUserDesignData } = useStoreActions((actions) => actions.designs);
  const { thunkGetProductDesignByProjectId} = useStoreActions((actions) => actions.designs);

  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const isFavoriteDesignPage = currentPath === FAVORITE_DESIGNS_PATH;

  useEffect(() => {
    updateProjectFromFavoriteDesign(projectName.trim());
  }, [userDesignData?.userCustomDesign.id]);
  
  const updateProjectFromFavoriteDesign = async (name) => {
    if (favoriteDesign && userDesignData) {
      
      const favoriteDesignInsert = favoriteDesign.insertDetails[0]
      let newInsertDetails: IInsertDetailRequest[] = [];

      if (userDesignData?.userCustomDesign.insertDetails && favoriteDesignInsert) {
        newInsertDetails = userDesignData.userCustomDesign.insertDetails.map((detail) => ({
          ...detail,
          insertColor: favoriteDesignInsert.insertColor,
          insertType: favoriteDesignInsert.insertType,
          items: favoriteDesignInsert.items.map((item) => ({
            color: item.color,
            coverIndex: item.coverIndex,
            dimension: item.dimension,
            iconId: item.iconId,
            iconName: item.iconName,
            indexDetail: item.indexDetail,
            insertDetailId: item.insertDetailId,
            materialNumber: item.materialNumber,
            position: item.position,
            type: item.type,
          })),
          id: userDesignData.userCustomDesign.insertDetails[0].id,
          rotate: favoriteDesignInsert.rotate,
          hasGridLine: favoriteDesignInsert.hasGridLine,
        }));
      }

      const newValues = {
        design: favoriteDesign.design,
        numberOfSwitches: 1,
        userCustomDesign: {
          id: userDesignData.userCustomDesign.id as string,
          name: name,
          isArchived: favoriteDesign.isArchived as boolean,
          designId: favoriteDesign.designId as string,
          projectId: userDesignData.userCustomDesign.projectId as string,
          frameFold: favoriteDesign.frameFold,
          frameColor: favoriteDesign.frameColor,
          isHorizontal: favoriteDesign.isHorizontal,
          pathname: favoriteDesign.pathname,
          insertDetails: newInsertDetails,
          quantity: favoriteDesign.quantity,
          room: favoriteDesign.room as string || " ",
          location: favoriteDesign.location as string || " ",
        },
      };

      setUserDesignData(newValues);

      await thunkAutoSave(newValues);
      await thunkGetProductDesignByProjectId(userDesignData.userCustomDesign.projectId!);

      navigate("/insert-configuration", {
        state: { isNewProject: true, fromFavoriteDesign: true },
      });
    }
  };

  
  const handleCreateProject = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    const name = projectName.trim();

    if (name.length > 0 && name.length < 51) {
   
        const payloadProject: IProjectCreateRequest = {
          projectName: name,
          company: process.env.REACT_APP_THEME == BJE ? ECompany.BJE : ECompany.ABB,
        };

        const resProject = await thunkCreateProject(payloadProject);

        if (resProject.status == Status.FAILED) {
          setLoading(false);

          thunkOpenSnackBar({
            message: t(resProject.message),
            severity: resProject.message == "CreateProject_AlreadyExists_Message" ? "warning" : "error",
          });
          return;
        }

      setLoading(false);

      thunkOpenSnackBar({
        message: t("configurator_welcome_dialog.create_project_response.success_message"),
        severity: "success",
      });

      handleClose();
    } else {
      thunkOpenSnackBar({
        message: t("configurator_welcome_dialog.create_project_response.alert_message"),
        severity: "warning",
      });
      return;
    }

    setLoading(false);
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  return (
    <>
      <Dialog
        PaperProps={{
          sx: { position: "fixed", top: "17%", m: 0 },
        }}
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "480px",
            },
          },
        }}
        open={open}
      >
        <DialogContent>
          <div className="dialogcontent__main">
            <div className="dialogcontent__body">
              <Typography
                sx={{
                  fontSize: "21px",
                  fontWeight: 700,
                }}
              >
                {t("configurator_welcome_dialog.label.new_project")}
              </Typography>
              <Typography
                variant="body1"
                mt={1}
              >
                {t("configurator_welcome_dialog.label.create_new_name_project")}
              </Typography>
              <div className="body__formControlContainer">
                <FormControl className="body__formControl">
                  <Typography variant="caption">{t("configurator_welcome_dialog.label.project_name")}</Typography>
                  <TextField
                    disabled={loading}
                    id="projectName"
                    inputProps={{
                      style: {
                        padding: 5,
                      },
                    }}
                    sx={{
                      margin: "20px 0 10px 0",
                      width: "100%",
                    }}
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    helperText={
                      projectName.trim().length > 50 ? t("configurator_welcome_dialog.helper_text.project_name") : ""
                    }
                    error={projectName.trim().length > 50 ? true : false}
                  />
                </FormControl>
              </div>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
            >
              <BackButton loading={loading} isFavoriteDesignPage={isFavoriteDesignPage} handleClose={handleClose} />
              <ConfirmButton
                loading={loading}
                disabled={loading || projectName.trim().length > 50 || projectName.trim().length === 0}
                handleCreateProject={handleCreateProject}
              />
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default forwardRef(ConfiguratorWelcomeDialog);
