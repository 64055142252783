import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import React, { RefObject, useState } from "react";
import { useStoreState } from "../../hooks";
import { IElementSize } from "../../interfaces/DTO/IElementSize";
import { IStyleProps } from "../../pages/Configurator/Components/GridLine/GridLine";
import DimensionLine from "../../pages/Configurator/InsertConfiguration/Tiles/DimensionLine";
import { getElementPosition } from "../../pages/Configurator/InsertConfiguration/Tiles/utils";
import './TooltipDimension.css';

interface IProps {
  children: React.ReactElement;
  coverRef: RefObject<HTMLDivElement>;
  iconRef?: RefObject<HTMLDivElement>;
  elementRef: RefObject<HTMLDivElement | HTMLCanvasElement>;
  styleProps?: IStyleProps;
  isFlipped?: boolean;
  getElementSize?: () => IElementSize;
  enableDimensionLine: boolean;
  enableDimensionBorder: boolean;
  enableStartEndPositions: boolean;
}

const TooltipDimension: React.FC<IProps> = (props) => {
  const { children, coverRef, elementRef, iconRef, getElementSize, enableDimensionLine, enableDimensionBorder, enableStartEndPositions } = props;
  const { enableDimensionComponent } = useStoreState((state) => state.app);
  const theme = useTheme();

  const [showPosition, setShowPosition] = useState<boolean>(false);

  const handleElementSize = () => {
    return getElementSize ? getElementSize : getDefaultSize;
  };

  const getDefaultSize = () => {
    const elementSize: IElementSize = {
      width: elementRef!.current?.offsetWidth!,
      height: elementRef!.current?.offsetHeight!,
    };

    return elementSize;
  };

  const formatValue = (value: number) => {
    return (Math.round(value * 100) / 100).toFixed(2)
  }

  const positions = getElementPosition(coverRef!, elementRef, handleElementSize())
  return (
    <>
      <Tooltip
        open={enableDimensionComponent && showPosition}
        arrow
        placement="right"
        onMouseOver={() => {
          setShowPosition(true);
        }}
        onMouseLeave={() => {
          setShowPosition(false);
        }}
        title={
          <>
            <Table size="small" className="tableDimension">
              <TableHead>
                <TableRow sx={{}}>
                  <TableCell size="small" sx={{ fontSize: '10px' }}>Measure</TableCell>
                  <TableCell size="small" sx={{ fontSize: '10px' }}>mm</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >

                {
                  !enableStartEndPositions &&
                  <>
                    <TableRow >
                      <TableCell size="small"><Typography variant="caption" color="primary">width:</Typography></TableCell>
                      <TableCell size="small">{positions?.widthmm.toFixed(2)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell><Typography variant="caption" color="primary">height:</Typography></TableCell>
                      <TableCell>{positions?.heightmm.toFixed(2)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell><Typography variant="caption" color="primary">x:</Typography></TableCell>
                      <TableCell>{formatValue(positions?.xmm!)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell><Typography variant="caption" color="primary">y:</Typography></TableCell>
                      <TableCell>{formatValue(positions?.ymm!)}</TableCell>
                    </TableRow>
                  </>
                }

                {
                  enableStartEndPositions &&
                  <>
                    <TableRow>
                      <TableCell><Typography variant="caption" color="primary">start x:</Typography></TableCell>
                      <TableCell>{positions?.startPositionXMm.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><Typography variant="caption" color="primary">start y:</Typography></TableCell>
                      <TableCell>{positions?.startPositionYMm.toFixed(2)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell><Typography variant="caption" color="primary">end x:</Typography></TableCell>
                      <TableCell>{positions?.endPositionXMm.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><Typography variant="caption" color="primary">end y:</Typography></TableCell>
                      <TableCell>{positions?.endPositionYMm.toFixed(2)}</TableCell>
                    </TableRow>
                  </>
                }
              </TableBody>
            </Table>
          </>
        }
      >
        <div
          ref={iconRef}
          style={{
            ...props.styleProps,
          }}
        >
          <div
            className={`${props.isFlipped ? "isFlipped" : ""}`}
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              display: 'flex',
              alignItems: 'center',
              ...{
                cursor: `${enableDimensionComponent && showPosition ? "pointer" : ""}`,
                // border: `${enableDimensionComponent && showPosition && enableDimensionBorder ? "1px solid red" : ""}`,
                outline: `${enableDimensionComponent && showPosition && enableDimensionBorder ? "0.2px solid red" : ""}`,
              },
            }}
          >
            <DimensionLine
              show={enableDimensionComponent && showPosition && enableDimensionLine}
              dimensions={getElementPosition(props.coverRef!, elementRef, handleElementSize())!}
            />
            {children}
          </div>
        </div>
      </Tooltip >
    </>
  );
};

export default TooltipDimension;
