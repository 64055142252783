import { Typography, Button, useTheme, Grid } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB } from "../../../constants/Theme";
import FavoriteDesignsCheckbox from "../../../components/FavoriteDesignsCheckbox/FavoriteDesignsCheckbox";
import { IInsertDetail } from "../../../interfaces/IInsertDetail";
import { IFrameColor } from "../../../interfaces/IFrameColor";
import { IFoundFavoriteDesigns } from "../../../interfaces/IFoundFavoriteDesigns";

interface IProps {
  id?: string;
  index: number;
  insertTypeName?: string;
  insertColorCode?: string;
  handleGoToInsertConfiguration: (selectedCoverNumber: number) => Promise<void>;
  detail: IInsertDetail;
  checkedFavoriteDesigns: IFoundFavoriteDesigns[];
  setCheckedFavoriteDesigns: React.Dispatch<React.SetStateAction<IFoundFavoriteDesigns[]>>;
}

const AbbMainSummaryCoverInfoContainer: React.FC<IProps> = ({
  id,
  index,
  insertTypeName,
  insertColorCode,
  handleGoToInsertConfiguration,
  detail,
  checkedFavoriteDesigns,
  setCheckedFavoriteDesigns,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment key={id}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "389px",
          height: "auto",
          padding: "8px 0 4px",
          borderTop: "1px solid #BABABA",
          borderBottom: "1px solid #BABABA",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className="designPreview__mainSummaryCoverInfoText">
            <Typography
              mt={2}
              variant="h4"
              fontSize="16px"
              fontWeight={600}
            >
              {t("design_preview_tabs.label.cover")} {index + 1}
            </Typography>
            <Typography
              fontSize="14px"
              fontWeight={400}
              color="#696969"
            >
              {t(`insertcolors.${insertColorCode}`)} | {t(`insert_type.${insertTypeName}`)}
            </Typography>
          </div>
          <Button
            className="designPreview__mainSummaryCoverbtn"
            variant="text"
            onClick={() => handleGoToInsertConfiguration(index)}
          >
            {t("design_preview.button.edit_cover")}
          </Button>
        </Grid>
        <FavoriteDesignsCheckbox item={detail} checkedFavoriteDesigns={checkedFavoriteDesigns} setCheckedFavoriteDesigns={setCheckedFavoriteDesigns} numberOfCover={index}/>
      </Grid>
    </Fragment>
  );
};

const BjeMainSummaryCoverInfoContainer: React.FC<IProps> = ({
  id,
  index,
  insertTypeName,
  insertColorCode,
  handleGoToInsertConfiguration,
  detail,
  checkedFavoriteDesigns,
  setCheckedFavoriteDesigns,
}) => {
  const { t } = useTranslation();
  
  return (
    <Fragment key={id}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "437px",
          height: "auto",
          padding: "8px 0 4px",
          borderTop: "1px solid #BABABA",
          borderBottom: "1px solid #BABABA",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className="designPreview__mainSummaryCoverInfoText">
            <Typography
              mt={2}
              variant="h4"
              fontSize="16px"
              fontWeight={600}
            >
              {t("design_preview_tabs.label.cover")} {index + 1}
            </Typography>
            <Typography
              fontSize="14px"
              fontWeight={400}
              color="#696969"
            >
              {t(`insertcolors.${insertColorCode}`)} | {t(`insert_type.${insertTypeName}`)}
            </Typography>
          </div>
          <Button
            className="designPreview__mainSummaryCoverbtn"
            variant="text"
            onClick={() => handleGoToInsertConfiguration(index)}
          >
            {t("design_preview.button.edit_cover")}
          </Button>
        </Grid>
        <FavoriteDesignsCheckbox item={detail} checkedFavoriteDesigns={checkedFavoriteDesigns} setCheckedFavoriteDesigns={setCheckedFavoriteDesigns} numberOfCover={index}/>
      </Grid>
    </Fragment>
  );
};

const MainSummaryCoverInfoContainer: React.FC<IProps> = ({ ...rest }) => {
  const ftsTheme = useTheme() as FTSTheme;
  return (
    <>
      {ftsTheme.name == ABB ? (
        <AbbMainSummaryCoverInfoContainer {...rest} />
      ) : (
        <BjeMainSummaryCoverInfoContainer {...rest} />
      )}
    </>
  );
};

export default MainSummaryCoverInfoContainer;
