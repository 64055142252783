import { LoadingButton } from "@mui/lab";
import { CircularProgress, Dialog, DialogContent, Grid, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { createRef, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useStoreActions } from "../../../hooks";
import { IContactUsPost } from "../../../interfaces/ContactUs/IContactUsPost";
import { IDialogHandle } from "../../../interfaces/IDialogHandle";
import CloseIcon from "../../../pages/Configurator/Icons/close.svg";
import ContactUsService from "../../../services/ContactUsService";
import { FTSTheme } from "../../../App";
import { ECompany } from "../../../enum/ECompany";
import FitText from "../../FitText/FitText";

export const contactUsDialogRef = createRef<IDialogHandle>();

interface IContactUsDialogInputProps {

}

const ContactUsDialog: ForwardRefRenderFunction<IDialogHandle, IContactUsDialogInputProps> = (props, ref) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;
  const { thunkOpenSnackBar } = useStoreActions(actions => actions.app);
  const { register, handleSubmit, formState: { errors, isValid }, reset, setError, clearErrors } = useForm()
  const handleSent = (form) => {
    setIsLoading(true);

    const payload: IContactUsPost = {
      email: form.email,
      subject: form.subject,
      message: form.message,
      company: theme.name == "abb" ? ECompany.ABB : ECompany.BJE
    }

    ContactUsService.Send(payload)
      .then(response => {
        thunkOpenSnackBar({
          message: t(`${theme.name}.contactus.message.success`),
          severity: 'success'
        })
        handleClose();

      })
      .catch((error) => {
        thunkOpenSnackBar({
          message: t(`${theme.name}.contactus.message.error`),
          severity: 'error'
        })
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    reset();
  }

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose
    };
  })

  const validateEmail = (value: string) => {
    const domainRegex = /@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|([a-z0-9][\w\.-]*[a-z0-9]\.[a-z][a-z\.]*[a-z]))$/g
    const domainPart = value.match(domainRegex)
    if (!domainPart) { return false }

    const local = value.replace(domainPart[0], '')
    const hasMultipleDots = local.match(/^(\.)|\.[\.]+|\.$/g)
    if (hasMultipleDots) { return false }

    const localNoEscapedChars = local.replaceAll(/\\[\"\(\),:;<> @\[\]\\]/g, '')
    const onlyLegalChars = localNoEscapedChars.match(/^(([!#$%&'*+\-/=?^_`{|}~.a-zA-Z0-9]+)|(\".+\"))$/g)
    if (!onlyLegalChars) { return false }

    return true
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "780px",
            },
          },
        }}
        fullWidth={true}
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={() => { contactUsDialogRef.current?.close() }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            width: 35,
            height: 35
          }}
        >
          <ReactSVG
            onClick={() => { contactUsDialogRef.current?.open() }}
            src={CloseIcon}
            beforeInjection={(svg) => {
              svg.setAttribute(
                "style",
                `width: 24px; height: 24px; padding: 0px; margin-top: 5px; fill: ${theme.icon.color}`
              );
            }}
          />
        </IconButton>
        <DialogContent>
          <form onSubmit={handleSubmit(handleSent, (form) => console.log(form))}>
            <Typography variant="h2" fontSize="21px" fontWeight={700}>
              {t(`${theme.name}.contactus.label.title`)}
            </Typography>

            <Typography mt="10px" variant="h2" fontSize="15px" fontWeight={400}>
              {t(`${theme.name}.contactus.label.message`)}
            </Typography>
            <Grid container mt={2}>

              <Grid item md={4}>
                <Grid container sx={{
                  padding: '15px',
                  marginTop: '0px',
                  backgroundColor: '#F5F5F5',
                  // height: '280px',
                  width: '229px'
                }}>
                  <Grid item md={12} sx={{
                    marginBottom: '25px'
                  }}>
                    <Typography variant="h2" fontSize="21px" fontWeight={700}>
                      {t(`${theme.name}.contactus.label.telefon`)}
                    </Typography>
                    <Typography variant="h2" fontSize="15px" fontWeight={400}>
                      {t(`${theme.name}.contactus.label.telefonnumber`)}
                    </Typography>
                  </Grid>

                  <Grid item md={12} sx={{
                    marginBottom: '25px'
                  }}>
                    <Typography variant="h2" fontSize="21px" fontWeight={700}>
                      {t(`${theme.name}.contactus.label.telefax`)}
                    </Typography>
                    <Typography variant="h2" fontSize="15px" fontWeight={400}>
                      {t(`${theme.name}.contactus.label.telefaxnumber`)}
                    </Typography>
                  </Grid>

                  <Grid item md={12} sx={{
                    marginBottom: '15px'
                  }}>
                    <Typography variant="h2" fontSize="20px" fontWeight={700}>
                      {t(`${theme.name}.contactus.label.email`)}
                    </Typography>
                    <Typography variant="body1" fontSize="15px" fontWeight={400} sx={{ textDecoration: "underline" }}>
                      {t(`${theme.name}.contactus.label.emailaddress`)}
                    </Typography>
                  </Grid>

                  <Grid item md={12}>
                    <Typography mt="18px" variant="h2" fontSize="15px" fontWeight={400} sx={{
                      whiteSpace: "pre-line"
                    }}>
                      <Trans
                        i18nkey={`${theme.name}.contactus.label.address`}
                        components={{ lineBreak: <br /> }}
                      >
                        {t(`${theme.name}.contactus.label.address`, { lineBreak: '\n' })}
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>


              <Grid item md={8}>
                <Typography variant="caption" fontSize="12px" fontWeight={400}>
                  {t(`${theme.name}.contactus.form.label.email`)}
                </Typography>
                <TextField
                  {...register("email", {
                    required: true,
                    validate: validateEmail
                  })
                  }
                  inputProps={{ maxLength: 64 }}
                  helperText={!!errors.email && errors.email.type == 'pattern' ? t(`${theme.name}.contactus.form.label.emailvalidation`) : ''}
                  fullWidth
                  sx={{ marginBottom: '12px' }}
                />
                <Typography variant="caption" fontSize="12px" fontWeight={400}>
                  {t(`${theme.name}.contactus.form.label.subject`)}
                </Typography>
                <TextField {...register("subject", { required: true })}
                  fullWidth
                  error={!!errors.subject}
                />

                <TextField
                  {...register("message", { required: true })}
                  fullWidth
                  multiline
                  maxRows={10}
                  inputProps={{
                    style: {
                      height: '103px',
                    }
                  }}
                />

              </Grid>

              <Grid md={12} container justifyContent="flex-end">
                <LoadingButton
                  loading={isLoading}
                  loadingIndicator={<CircularProgress color="info" size={20} />}
                  disabled={!isValid}
                  type="submit"
                  variant="contained"
                  sx={{
                    width: '193px',
                    padding: '12px',
                    marginTop: '36px',
                    backgroundColor: `${!isValid || isLoading ? '#BABABA !important' : ''}`
                  }}>
                  <FitText>{t(`${theme.name}.contactus.form.button.send`)}</FitText>
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </DialogContent>

      </Dialog>
    </>
  )
}

export default forwardRef(ContactUsDialog);