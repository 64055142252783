import React, { useState, ForwardRefRenderFunction, createRef, forwardRef, useImperativeHandle } from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography,useTheme } from "@mui/material";
import "./TermsAndCondition.css";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import UserService from "../../services/UserService";
import { ILoggedUser } from "../../interfaces/DTO/ILoggedUser";
import { useStoreActions } from "../../hooks";
import { handleError } from "../../utils/handleError";
import store from "../../store";
import { getSignOutHref } from "../../auth/config";
import logging from "../../config/logging";
import { useAuth } from "../../auth/utils";
import { FTSTheme } from "../../App";
import { BJE } from "../../constants/Theme";
import { IDialogHandle } from "../../interfaces/IDialogHandle";
import { IContent } from "../../interfaces/IContent";

interface ITermsAndConditionProps {
  content: IContent;
  currentUser: ILoggedUser;
}

export const termsAndConditionDialogRef = createRef<IDialogHandle>();

const TermsAndConditionDialog: ForwardRefRenderFunction<IDialogHandle, ITermsAndConditionProps> = (props, ref) => {
  const { t } = useTranslation();
  const { setCurrentUser } = useStoreActions((actions) => actions.auth);
  const { actions: auth } = useAuth();
  const [open, setOpen] = useState(false);
  const theme = useTheme() as FTSTheme;
  const isBje = theme.name == BJE;

    const cleanHTML = DOMPurify.sanitize(props.content.value, { USE_PROFILES: { html: true } });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
      return {
          open: handleOpen,
          close: handleClose,
      };
  });

  const handleAcceptTerms = () => {
      UserService.AcceptTermsAndConditions(props.currentUser.email)
      .then((response) => {
        const userDto = response.data;
        const updatedUser: ILoggedUser = {
          email: userDto.email,
          name: userDto?.name!,
          countryId: userDto.countryId,
          languageId: userDto.languageId,
          isElBridgeAvailable: userDto.isElBridgeAvailable,
          termsAndConditionsAccepted: userDto.termsAndConditionsAccepted,
        };
        setCurrentUser(updatedUser);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleRejectTerms = () => {
    store.persist
      .clear()
      .then(() => {
        auth.removeAllData();
        window.location.replace(getSignOutHref());
        const updatedUser: ILoggedUser = {
            ...(props.currentUser || {}),
          termsAndConditionsAccepted: false
        };

        setCurrentUser(updatedUser);
      })
      .catch((err) => logging.error(err));
    handleClose();
  };

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          width: "664px",
          height: "613px",
          padding: "32px 24px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Typography className="terms-and-condition-dialog-title">{t("terms_and_conditions_title")}</Typography>
        <Grid
          container
          className="terms-and-condition-dialog-body"
        >
          <Grid
            item
            className="terms-and-condition-dialog-body-content"
          >
            <Typography>
              <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              onClick={() => handleRejectTerms()}
              className="terms-and-condition-back-button"
            >
              {isBje ? t("terms_and_conditions_backbutton_bje") : t("terms_and_conditions_backbutton")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleAcceptTerms()}
              className="terms-and-condition-accept-button"
              variant="contained"
            >
              {t("terms_and_conditions_acceptbutton")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(TermsAndConditionDialog);
