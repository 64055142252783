import { Dispatch, RefObject, SetStateAction, useLayoutEffect, useState } from "react";

//third part libraries
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { FormControlLabel, Grid, MenuItem, Select, Switch, Tooltip, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

//App
import { EInsertType } from "../../../enum/EInsertType";
import { useStoreActions, useStoreState } from "../../../hooks";
import { IIcon } from "../../../interfaces/IIcon";
import { IIconCategory } from "../../../interfaces/IIconCategory";
import { EInsertDetailItemType, IInsertDetail, IInsertDetailItemDimension } from "../../../interfaces/IInsertDetail";
import { ITileDimension } from "../../../interfaces/ITileDimension";
import { ICanvasForwardProps } from "../../../pages/Configurator/InsertConfiguration/Tiles/Canvas";
import { IDimension } from "../../../pages/Configurator/InsertConfiguration/Tiles/DimensionLine";
import { ONE_PIXEL_IN_MILLITER } from "../../../pages/Configurator/InsertConfiguration/Tiles/utils";
import CoverItemsFunctions from "../../../pages/Configurator/InsertConfiguration/Tiles/utils-cover-items";
import CoverItemsRefsFunctions from "../../../pages/Configurator/InsertConfiguration/Tiles/utils-cover-items-refs";
import { FTSTheme } from "../../../App";
import { capitalizeFirstLowercaseRest, truncate } from "../../../utils/stringHelper";
import { EIconPercentSize, EIconSizePixelToPercent } from "../../../enum/EIconSize";
import TextAreaControlled from "./TextAreaControlled";
import IconDialogInfoBox from "./IconDialogInfoBox";
import { ReactComponent as StartFilledIcon } from "../../../pages/Configurator/Icons/starFilledIcon.svg";
import { ReactComponent as StartNotFilledIcon } from "../../../pages/Configurator/Icons/startNotFilledIcon.svg";
import "./IconDialog.css";
import { SET_OF_ICONS_PRINTING, SET_OF_ICONS_PRINTING_2022 } from "../../../constants/SetOfIcons";
import { ALBA, BUSCH_ART_LINEAR } from "../../../constants/DesignTypes";
import { IDesign } from "../../../interfaces/IDesign";
import FitText from "../../FitText/FitText";
import CloneDialog from "../Clone/CloneDIalog";
import { IDisplayHighlightedWarning, IHandleTextInputChangeParams } from '../../../interfaces/ITextAreaControlled';

interface IIconDialogProps {
  tileDimension: ITileDimension;
  indexDetail: string;
  cover: number;
  insertDetail: IInsertDetail | null;
  anchorEl: HTMLElement | null;
  open: boolean;
  showCanvas: boolean;
  textAreaValue: string;
  fontSizeCanvas: number;
  lineHeight: number;
  canvasComponentRef: React.RefObject<ICanvasForwardProps>;
  displayHighlightedWarning: IDisplayHighlightedWarning;
  currentCanvasValue: string;
  tileType?: EInsertType;
  isFunction: boolean;
  recentlyIcons: IIcon[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  setInsertDetail: (detail: IInsertDetail) => void;
  setIconDimension: (value: IInsertDetailItemDimension) => void;
  setShowCanvas: Dispatch<SetStateAction<boolean>>;
  handleTextInputChange: (params: IHandleTextInputChangeParams) => void;
  setTextAreaValue: Dispatch<SetStateAction<string>>;
  handleDecreaseFont: () => void;
  handleIncreaseFont: () => void;
  handleResetAll: (insertDetail: IInsertDetail) => void;
  handleOnIconSelected: () => IDimension;
  setRecentlyIcons: (value: IIcon[] | ((val: IIcon[]) => IIcon[])) => void;
}

const IconDialogMain: React.FC<IIconDialogProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const ftsTheme = useTheme() as FTSTheme;

  const { icons, iconCategories, productDesigns, isActiveNewSetOfIcons } = useStoreState((state) => state.designs.data);
  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const { currentProjectId } = useStoreState((state) => state.designs.configuration);
  const { thunkGetProductDesignByProjectId, setIsActiveNewSetOfIcons } = useStoreActions((action) => action.designs);
  const [categoriesDisplayed, setCategoriesDisplayed] = useState<IIconCategory[]>([]);
  const [iconsDisplayed, setIconsDisplayed] = useState<IIcon[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [category, setCategory] = useState<IIconCategory | null>(null);
  const [iconSizePercentage, setIconSizePercentage] = useState<number>(100);
  const [selectedIcon, setSelectedIcon] = useState<IIcon | null>(null);
  const [recentlyUsed, setRecentlyUsed] = useState(false);
  const [openCloneDialog, setOpenCloneDialog] = useState(false);
  const [closeCloneDialog, setCloseCloneDialog] = useState(false);

  const iconSize = [
    {
      percentage: EIconPercentSize.SMALL,
      size: parseFloat((props.tileDimension.iconPixelDefault! * (EIconPercentSize.SMALL / 100)).toFixed(0)),
    },
    {
      percentage: EIconPercentSize.STANDARD,
      size: parseFloat((props.tileDimension.iconPixelDefault! * (EIconPercentSize.STANDARD / 100)).toFixed(0)),
    },
    {
      percentage: EIconPercentSize.MEDIUM,
      size: parseFloat((props.tileDimension.iconPixelDefault! * (EIconPercentSize.MEDIUM / 100)).toFixed(0)),
    },
    {
      percentage: EIconPercentSize.LARGE,
      size: parseFloat((props.tileDimension.iconPixelDefault! * (EIconPercentSize.LARGE / 100)).toFixed(0)),
    },
    {
      percentage: EIconPercentSize.XLARGE,
      size: parseFloat((props.tileDimension.iconPixelDefault! * (EIconPercentSize.XLARGE / 100)).toFixed(0)),
    },
  ];
  const initIconsStorage = () => {
    const allIcons = productDesigns
      .flatMap((x) => x.insertDetails)
      .flatMap((item) => item.items)
      .filter((item) => item?.type === EInsertDetailItemType.Icon);

    const allIconsId = allIcons.map((x) => x?.iconId);
    const iconsStorage = icons.filter((x) => allIconsId.includes(x.id));

    return iconsStorage;
  };

  const initialState = () => {
    var iconsStorage = localStorage.getItem("@FTS:Icons") ?? "[]";
    var icons = JSON.parse(iconsStorage) as IIcon[];
    props.setRecentlyIcons(icons);

    initIconSizePercentage();
    initIconsStorage();
    initialIcon();
  };

  const initialIcon = () => {
    if (props.insertDetail && props.insertDetail?.items != null) {
      const initialIconId = props.insertDetail?.items.find(
        (item) => item.type == EInsertDetailItemType.Icon && item.indexDetail == props.indexDetail
      )?.iconId;

      const initialIcon = icons.find((icon) => icon.id == initialIconId);
      if (initialIcon) {
        setSelectedIcon(initialIcon);
      }

      var iconItems = props.insertDetail?.items
        .filter((item) => item.type == EInsertDetailItemType.Icon && item.iconId)
        .map((s) => s.iconId);

      const savedIcon = icons.some(
        (item) => iconItems?.includes(item.id.toString()) && item.folderName == SET_OF_ICONS_PRINTING_2022
      );

      if (initialIcon?.folderName == SET_OF_ICONS_PRINTING_2022 || savedIcon) {
        setIsActiveNewSetOfIcons(true);
      } else {
        setIsActiveNewSetOfIcons(false);
      }
    }
  };

  const initIconSizePercentage = () => {
    if (props.insertDetail && props.insertDetail?.items != null) {
      const dimension = props.insertDetail?.items.find(
        (item) => item.type == EInsertDetailItemType.Icon && item.indexDetail == props.indexDetail
      )?.dimension;

      const size = parseFloat((dimension?.width! / ONE_PIXEL_IN_MILLITER).toFixed(0));
      if (size) {
        setIconSizePercentage(EIconSizePixelToPercent.get(props.tileDimension.isFunction!)?.get(size)!);
      }
    }
  };

  const loadIcons = () => {
    const shouldFilterNewSetOfIcons = IsNewSetOfIcons() || isActiveNewSetOfIcons;

    if (props.tileType == EInsertType.Slider) {
      setIconsDisplayed(
        props.insertDetail!.insertType!.icons!.filter(
          (x) => x.folderName == (shouldFilterNewSetOfIcons ? SET_OF_ICONS_PRINTING_2022 : SET_OF_ICONS_PRINTING)
        )
      );
      return;
    }

    const iconsToBeDisplayed = shouldFilterNewSetOfIcons
      ? icons.filter((x) => x.folderName == SET_OF_ICONS_PRINTING_2022)
      : icons.filter((x) => x.folderName == SET_OF_ICONS_PRINTING);

    setIconsDisplayed(iconsToBeDisplayed);
    setIconCategoryByIconSets(
      iconsToBeDisplayed,
      shouldFilterNewSetOfIcons ? SET_OF_ICONS_PRINTING_2022 : SET_OF_ICONS_PRINTING
    );
  };

  const setIconCategoryByIconSets = (icon: IIcon[], folderName: string) => {
    var result = [""];
    var returnIcons = icon.filter((x) => x.folderName == folderName);
    returnIcons.forEach((element) => {
      element.category.forEach((category) => {
        if (result.length <= 0 || result.indexOf(category.id) === -1) result.push(category.id);
      });
    });
    setCategoriesDisplayed(iconCategories.filter((item) => result.includes(item.id)));
  };

  useLayoutEffect(() => {
    initialState();
  }, [props.open, openCloneDialog]);

  useLayoutEffect(() => {
    loadIcons();
  }, [icons, props.insertDetail?.insertType?.icons, isActiveNewSetOfIcons]);

  const handleIconSelect = (icon: IIcon) => {
    setSelectedIcon(icon);

    const index = props.recentlyIcons.findIndex((x) => x.id == icon.id);
    if (index != -1) props.recentlyIcons.splice(index, 1);

    props.setRecentlyIcons((prev) => [icon, ...prev]);

    const iconColor = props.tileDimension.isFunction
      ? props.insertDetail?.insertColor?.secondaryPrintColor?.ralColorCode!
      : props.insertDetail?.insertColor?.primaryPrintColor?.ralColorCode!;

    CoverItemsFunctions.addOrUpdateIcon({
      iconId: icon.id,
      iconName: icon.name,
      materialNumber: icon.materialNumber,
      indexDetail: props.indexDetail,
      insertDetail: props.insertDetail!,
      setInsertDetail: props.setInsertDetail,
      coverIndex: props.cover,
      color: iconColor,
    });
  };

  const clickAwayHandler = () => {
    if (!openCloneDialog && !closeCloneDialog) {
      if (!props.textAreaValue) {
        props.setShowCanvas(false);
      }
      setRecentlyUsed(false);
      props.setOpen(false);
    } else if (!openCloneDialog && closeCloneDialog) {
      setCloseCloneDialog(false);
    }
  };

  const handleSetIconRefDimension = async (ref: RefObject<any>, dimension: number) => {
    (ref.current as HTMLDivElement).setAttribute("style", `width: ${dimension}px; height: ${dimension}px`);
  };

  const handleDecrement = async () => {
    if (iconSizePercentage > 89) {
      const iconDimension = iconSize.find((x) => x.percentage == iconSizePercentage - 10)?.size!;
      const iconColor = props.tileDimension.isFunction
        ? props.insertDetail?.insertColor?.secondaryPrintColor?.ralColorCode!
        : props.insertDetail?.insertColor?.primaryPrintColor?.ralColorCode!;

      const iconRef = CoverItemsRefsFunctions.exists(EInsertDetailItemType.Icon, props.indexDetail, props.cover);
      await handleSetIconRefDimension(iconRef.item?.itemRef!, iconDimension);

      CoverItemsFunctions.addOrUpdateIcon({
        iconId: selectedIcon?.id!,
        iconName: selectedIcon?.name!,
        materialNumber: selectedIcon?.materialNumber!,
        indexDetail: props.indexDetail,
        insertDetail: props.insertDetail!,
        setInsertDetail: props.setInsertDetail,
        coverIndex: props.cover,
        color: iconColor,
        iconSize: iconDimension * ONE_PIXEL_IN_MILLITER,
      });

      props.setIconDimension({
        height: iconDimension,
        width: iconDimension,
      });

      setIconSizePercentage((prev) => prev - 10);
    }
  };

  const handleIncrement = async () => {
    if (iconSizePercentage < 111) {
      const iconDimension = iconSize.find((x) => x.percentage == iconSizePercentage + 10)?.size!;
      const iconColor = props.tileDimension.isFunction
        ? props.insertDetail?.insertColor?.secondaryPrintColor?.ralColorCode!
        : props.insertDetail?.insertColor?.primaryPrintColor?.ralColorCode!;

      const iconRef = CoverItemsRefsFunctions.exists(EInsertDetailItemType.Icon, props.indexDetail, props.cover);
      await handleSetIconRefDimension(iconRef.item?.itemRef!, iconDimension);

      CoverItemsFunctions.addOrUpdateIcon({
        iconId: selectedIcon?.id!,
        iconName: selectedIcon?.name!,
        materialNumber: selectedIcon?.materialNumber!,
        indexDetail: props.indexDetail,
        insertDetail: props.insertDetail!,
        setInsertDetail: props.setInsertDetail,
        coverIndex: props.cover,
        color: iconColor,
        iconSize: iconDimension * ONE_PIXEL_IN_MILLITER,
      });

      props.setIconDimension({
        height: iconDimension,
        width: iconDimension,
      });

      setIconSizePercentage((prev) => prev + 10);
    }
  };

  const handleClone = () => {
    setOpenCloneDialog(true)
  };

  const handleReset = () => {
    const iconDimension = iconSize.find((x) => x.percentage == 100)?.size!;

    setSelectedIcon(null);
    props.setIconDimension({
      height: iconDimension,
      width: iconDimension,
    });

    setIconSizePercentage(100);
    props.setTextAreaValue("");
    props.setShowCanvas(false);

    CoverItemsFunctions.RemoveByInsertDetailIdAndTypes(props.insertDetail?.id!, props.indexDetail, [
      EInsertDetailItemType.Icon,
      EInsertDetailItemType.Text,
    ]);

    const newwInsertDetail = {
      ...props.insertDetail,
      ...{
        items: [
          ...(props.insertDetail?.items.filter(
            (item) =>
              item.indexDetail != props.indexDetail ||
              ![EInsertDetailItemType.Icon, EInsertDetailItemType.Text].includes(item.type)
          ) ?? []),
        ],
      },
    };

    props.setInsertDetail(newwInsertDetail);
    props.handleResetAll(newwInsertDetail);
  };

  const handleSave = () => {
    if (!props.textAreaValue) {
      props.setShowCanvas(false);
      props.handleResetAll(props.insertDetail!);
    }
    props.setOpen(false);
  };

  const handleRecentlyUsed = () => {
    const isRecentlyUsedActivated = !recentlyUsed;
    setRecentlyUsed(isRecentlyUsedActivated);

    if (isRecentlyUsedActivated == false) {
      loadIcons();
      return;
    }

    const shouldFilterNewSetOfIcons = isActiveNewSetOfIcons || IsNewSetOfIcons();

    if (props.tileType == EInsertType.Slider) {
      const sliderIcons = props.insertDetail!.insertType!.icons!;
      const iconsToBeDisplayed = props.recentlyIcons.filter((x) => sliderIcons.map((x) => x.id).includes(x.id));

      setIconsDisplayed(
        iconsToBeDisplayed?.filter(
          (x) => x.folderName == (shouldFilterNewSetOfIcons ? SET_OF_ICONS_PRINTING_2022 : SET_OF_ICONS_PRINTING)
        )
      );
      return;
    }

    if (isActiveNewSetOfIcons || IsNewSetOfIcons()) {
      setIconsDisplayed(props.recentlyIcons.filter((x) => x.folderName == SET_OF_ICONS_PRINTING_2022));
      return;
    }

    setIconsDisplayed(props.recentlyIcons.filter((x) => x.folderName == SET_OF_ICONS_PRINTING));
    return;
  };

  const handleFilterIcons = () => {
    if (category == null) {
      return iconsDisplayed.filter(
        (icon) =>
          icon.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          icon.description.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          icon.materialNumber.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          icon.category.map((x) => x.name.toLocaleLowerCase()).includes(keyword.toLocaleLowerCase())
      );
    }
    return iconsDisplayed.filter((icon) => icon.category.map((x) => x.id).includes(category.id));
  };

  const handleSwitchShowCanvas = (checked: boolean) => {
    props.setShowCanvas(checked);
    if (!checked) {
      props.setTextAreaValue("");
      props.setShowCanvas(false);
      CoverItemsFunctions.RemoveByInsertDetailIdAndTypes(props.insertDetail?.id!, props.indexDetail, [
        EInsertDetailItemType.Text,
      ]);
      const newInsertDetail = {
        ...props.insertDetail,
        ...{
          items: [
            ...(props.insertDetail?.items.filter(
              (item) =>
                item.type != EInsertDetailItemType.Text ||
                (item.type == EInsertDetailItemType.Text && item.indexDetail != props.indexDetail)
            ) ?? []),
          ],
        },
      };

      props.setInsertDetail(newInsertDetail);
      props.canvasComponentRef.current?.refresh(props.insertDetail?.rotate, newInsertDetail, true);
    }
  };

  const handleSwitchSetOfIcons = () => {
    const newStatus = !isActiveNewSetOfIcons;
    setIsActiveNewSetOfIcons(newStatus);
  };

  const shouldShowSwitchIconButton = () => {
    return !IsNewSetOfIcons();
  };

  const IsNewSetOfIcons = () => {
    let design: IDesign = {} as IDesign;

    if (productDesigns.length == 0) {
      thunkGetProductDesignByProjectId(currentProjectId!);
    }

    design = userDesignData?.design!;
    return [BUSCH_ART_LINEAR, ALBA].includes(design?.name!);
  };

  const hasUniqueSetOfIcons = () => {
    var iconItems = props.insertDetail?.items
      .filter((item) => item.type == EInsertDetailItemType.Icon && item.iconId)
      .map((s) => s.iconId);
    const savedIcon = icons.some(
      (item) => iconItems?.includes(item.id.toString()) && item.folderName == SET_OF_ICONS_PRINTING_2022
    );
    return (
      props.insertDetail?.items.findIndex((x) => x.coverIndex == props.cover && x.type == EInsertDetailItemType.Icon) ==
      -1 && !savedIcon
    );
  };

  return (
    <>
      <Popper
        open={props.open}
        anchorEl={props.anchorEl}
        placement="bottom"
        transition
      >

        {({ TransitionProps }) => {
          return (
            <>
              {(openCloneDialog) && (
                <CloneDialog setOpenCloneDialog={setOpenCloneDialog} setCloseCloneDialog={setCloseCloneDialog} insertDetails={props.insertDetail} setIconDialogOpen={props.setOpen} />
              )}
              <ClickAwayListener onClickAway={clickAwayHandler}>
                <Fade
                  {...TransitionProps}
                  timeout={0}
                  style={{ marginTop: props.showCanvas ? `${props.textAreaValue.split("\n").length * 30}px` : "10px" }}
                >
                  <Box
                    className="iconDialog"
                    sx={{
                      borderRadius: "4px",
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
                      bgcolor: "white",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "7px",
                    }}
                  >

                    <div
                      className="iconDialog__boxArrowUp"
                      style={{
                        width: 0,
                        height: 0,
                        marginTop: "-7px",
                        borderLeft: "8px solid transparent",
                        borderRight: "8px solid transparent",
                        borderBottom: "8px solid #fff",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    ></div>

                    <Box sx={{ margin: "16px 16px 0 16px" }}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "20px",
                        }}
                      >
                        {t("InsertConfiguration_IconDialog_Title")}
                      </Typography>


                      {props.isFunction && (
                        <Grid
                          container
                          className="iconDialog__info"
                        >
                          <IconDialogInfoBox />
                        </Grid>
                      )}
                    </Box>

                    <Box
                      className="iconDialog__filters"
                      sx={{ display: "flex", margin: "16px 16px 0 16px" }}
                    >
                      <Grid container>
                        <Grid
                          item
                          md={3}
                        >
                          <Tooltip
                            placement="top-start"
                            title={t("icon_modal.tooltip")}
                            slotProps={{
                              tooltip: {
                                style: {
                                  fontSize: "12px",
                                  fontWeight: 400,
                                },
                                width: "265px",
                                height: "28px",
                              },
                            }}>

                            <TextField
                              className="icon-dialog-search-filter"
                              variant="outlined"
                              placeholder={t("InsertConfiguration_IconDialog_SearchPlaceholder")}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchOutlinedIcon />
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{
                                style: {
                                  padding: 9,
                                  fontSize: "14px",
                                },
                              }}
                              fullWidth
                              value={keyword}
                              onChange={(e) => {
                                setKeyword(e.target.value.trimStart());
                              }} />
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          md={3}

                        >

                          <Autocomplete
                            noOptionsText={t("icons_dialog.no_category_found")}
                            value={category ?? null}
                            onChange={(event: any, newValue: IIconCategory | null) => {
                              setCategory(newValue);
                            }}
                            options={categoriesDisplayed}
                            getOptionLabel={(option) => option.name && option.name.length < 12
                              ? capitalizeFirstLowercaseRest(t(`icons.${option.name}`))
                              : option.name && option.name.length > 11
                                ? truncate(capitalizeFirstLowercaseRest(t(`icons.${option.name}`)), 11)
                                : ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="icon-dialog-categories-files"
                                placeholder={t("InsertConfiguration_IconDialog_CategoriesPlaceholder")}
                                inputProps={{
                                  style: {
                                    padding: 0,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    fontSize: "14px",
                                  },
                                  ...params.inputProps,
                                }} />
                            )} />
                        </Grid>

                        <Grid
                          item
                          md={3}
                        >
                          <Button
                            variant="text"
                            onClick={handleRecentlyUsed}
                            startIcon={recentlyUsed ? (
                              <StartFilledIcon
                                width={16}
                                height={16}
                                fill={`${theme.palette.primary.main}`} />
                            ) : (
                              <StartNotFilledIcon
                                width={16}
                                height={16}
                                fill={`${theme.palette.primary.main}`} />
                            )}
                            className="icon-dialog-recently-used-button"
                          >
                            <FitText>{t("InsertConfiguration_IconDialog_RecentlyUsed")}</FitText>
                          </Button>
                        </Grid>

                        <Grid
                          item
                          md={3}
                          container
                          justifyContent="flex-end"
                        >
                          <div className="icon-dialog-content-icon-size">
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              {t("InsertConfiguration_IconDialog_IconSizeLabel")}:
                            </Typography>
                            <ButtonGroup
                              size="small"
                              aria-label="large button group"
                            >
                              <IconButton
                                className="icon-dialog-buttons"
                                size="small"
                                aria-label="subtract"
                                color="primary"
                                onClick={handleDecrement}
                                disabled={iconSizePercentage === props.tileDimension.iconMinPercentage}
                              >
                                <RemoveOutlinedIcon
                                  style={iconSizePercentage !== props.tileDimension.iconMinPercentage
                                    ? { fill: ftsTheme.icon.color }
                                    : { fill: "#BABABA" }} />
                              </IconButton>
                              <Button
                                className="icon-dialog-buttons"
                                disabled
                                variant="text"
                                size="small"
                              >
                                <span style={{ color: "#1D1D1B" }}>{iconSizePercentage}%</span>
                              </Button>
                              <IconButton
                                className="icon-dialog-buttons"
                                size="small"
                                aria-label="subtract"
                                color="primary"
                                onClick={handleIncrement}
                                disabled={iconSizePercentage === props.tileDimension.iconMaxPercentage}
                              >
                                <AddOutlinedIcon
                                  style={iconSizePercentage !== props.tileDimension.iconMaxPercentage
                                    ? { fill: ftsTheme.icon.color }
                                    : { fill: "#BABABA" }} />
                              </IconButton>
                            </ButtonGroup>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box
                      className="iconDialog__icons"
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(14, 1fr)",
                        rowGap: "16px",
                        justifyContent: "start",
                        justifyItems: "center",
                        height: "155px",
                        gridAutoRows: "20px",
                        overflowY: "scroll",
                        padding: "2px 0",
                        margin: "16px 0 16px 16px",
                      }}
                    >
                      {handleFilterIcons().map((icon) => (
                        <div
                          key={icon.id}
                          style={selectedIcon?.id === icon.id
                            ? {
                              outline: "2px solid #009FE3",
                              width: "19.84px",
                              height: "19.84px",
                              cursor: "pointer",
                            }
                            : {
                              width: "19.84px",
                              height: "19.84px",
                              cursor: "pointer",
                            }}
                        >
                          <img
                            alt="icon_selection"
                            src={icon.blobUrl}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            onClick={() => handleIconSelect(icon)} />
                        </div>
                      ))}
                    </Box>

                    {shouldShowSwitchIconButton() && (
                      <Box
                        sx={{
                          mt: "5px",
                          ml: "25px",
                        }}
                      >
                        <Button
                          disabled={!hasUniqueSetOfIcons()}
                          onClick={handleSwitchSetOfIcons}
                          sx={{
                            width: "198px",
                            height: "40.09px",
                          }}
                          variant="outlined"
                        >
                          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                            {isActiveNewSetOfIcons
                              ? t("icon_dialog.switch_old_icon_set_button")
                              : t("icon_dialog.switch_icon_set_button")}
                          </Typography>
                        </Button>
                      </Box>
                    )}

                    <Box
                      className="icon-dialog-box-text-label"
                      sx={{ display: "flex", margin: "16px 16px 16px 16px" }}
                    >
                      <Grid container>
                        <Grid
                          item
                          md={6}
                          container
                          alignItems="center"
                        >
                          <FormControlLabel
                            control={<Switch
                              onChange={(e, checked) => handleSwitchShowCanvas(checked)}
                              size="small"
                              checked={props.showCanvas}
                              defaultChecked={props.showCanvas}
                              value={props.showCanvas}
                              sx={{
                                ".MuiSwitch-track": {
                                  backgroundColor: props.showCanvas ? "" : "black",
                                },
                              }}
                            />}
                            label={`${t("InsertConfiguration_IconDialog_SwitchAddTextLabel")}:`}
                            componentsProps={{
                              typography: {
                                fontWeight: "400",
                                fontSize: "14px",
                                height: "20px",
                              },
                            }}

                            labelPlacement="end"
                            style={{ marginLeft: "0px", fontSize: "14px" }} />
                        </Grid>
                        {props.showCanvas && (
                          <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            item
                            md={props.showCanvas ? 6 : 0}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              {t("InsertConfiguration_IconDialog_FontSizeLabel")}:
                            </Typography>
                            <ButtonGroup
                              className="icon-dialog-button-group-font-size"
                              size="small"
                              aria-label="large button group"
                            >
                              <IconButton
                                className="icon-dialog-buttons"
                                size="small"
                                aria-label="subtract"
                                color="primary"
                                onClick={props.handleDecreaseFont}
                                disabled={props.fontSizeCanvas === props.tileDimension.fontSizeMin}
                              >
                                <RemoveOutlinedIcon
                                  style={props.fontSizeCanvas !== props.tileDimension.fontSizeMin
                                    ? { fill: ftsTheme.icon.color }
                                    : { fill: "#BABABA" }} />
                              </IconButton>
                              <Button
                                className="icon-dialog-buttons"
                                disabled
                                variant="text"
                                size="small"
                              >
                                <span style={{ color: "#1D1D1B", textTransform: "lowercase" }}>{props.fontSizeCanvas}pt</span>
                              </Button>
                              <IconButton
                                className="icon-dialog-buttons"
                                size="small"
                                aria-label="subtract"
                                color="primary"
                                onClick={props.handleIncreaseFont}
                                disabled={props.fontSizeCanvas === props.tileDimension.fontSizeMax}
                              >
                                <AddOutlinedIcon
                                  style={props.fontSizeCanvas !== props.tileDimension.fontSizeMax
                                    ? { fill: ftsTheme.icon.color }
                                    : { fill: "#BABABA" }} />
                              </IconButton>
                            </ButtonGroup>
                          </Grid>
                        )}

                        {props.showCanvas && (
                          <Grid
                            item
                            md={props.showCanvas ? 12 : 0}
                          >
                            <TextAreaControlled
                              textAreaValue={props.textAreaValue}
                              displayHighlightedWarning={props.displayHighlightedWarning}
                              handleTextInputChange={props.handleTextInputChange}
                              fontSize={props.fontSizeCanvas}
                            />
                            {props.displayHighlightedWarning.display && props.textAreaValue.length > 0 && (
                              <div className="icon-dialog-div-highlighted-warning">
                                <Typography className="icon-dialog-text-highlighted-warning">
                                  {t("icon_dialog.helper_text_characters_limit_exceeded")}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        )}
                        <Grid
                          style={{ marginTop: "15px" }}
                          item
                          md={props.showCanvas ? 12 : 6}
                          container
                          justifyContent="flex-end"
                        >
                          <Button
                            sx={{
                              textTransform: "none",
                              marginRight: "8px",
                              width: "80px",
                              height: "32px",
                              fontSize: "14px",
                            }}
                            variant="text"
                            onClick={handleReset}
                          >
                            {t("InsertConfiguration_IconDialog_ButtonResetAll")}
                          </Button>
                          {(userDesignData?.numberOfSwitches! > 1) && (
                            <Button
                              sx={{
                                textTransform: "none",
                                marginRight: "8px",
                                width: "120px",
                                height: "32px",
                                fontSize: "14px",
                              }}
                              variant="text"
                              onClick={handleClone}
                            >
                              {t("InsertConfiguration_IconDialog_clone_from_button")}
                            </Button>
                          )}
                          <Button
                            sx={{
                              textTransform: "none",
                              width: "80px",
                              height: "32px",
                              fontSize: "14px",
                            }}
                            variant="contained"
                            onClick={handleSave}
                          >
                            {t("InsertConfiguration_IconDialog_ButtonSave")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Fade>
              </ClickAwayListener>
            </>
          );
        }}
      </Popper>

    </>
  );

};

export default IconDialogMain;
