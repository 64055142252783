import { Box, Button, Divider, IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ExternalLayout from "../../../components/Layout/ExternalLayout";
import { useStoreActions, useStoreState } from "../../../hooks";
import { useState, useEffect, useRef } from "react";
import EditDialog, { editDialogRef } from "./Edit/EditDialog";
import { Status } from "../../../models/status";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./ProjectSummary.css";
import "./ProjectSummary-1366.css";
import Spinner from "../../../components/Spinner/Spinner";
import ProjectService from "../../../services/ProjectService";
import DownloadProjectDialog, { downloadProjectDialogRef } from "./Download/DownloadProjectDialog";
import { useBackdrop } from "../../../hooks/backdrop";
import ProjectSummaryTable from "./Table/ProjectSummaryTable";
import { IProductDesignFilter } from "../../../interfaces/Filter/IProductDesignFilter";
import { EDesignStatus } from "../../../enum/EDesignStatus";
import { IProductDesignSummaryDto } from "../../../interfaces/DTO/IProductDesignSummaryDto";
import { FTSTheme } from "../../../App";
import OrderSummary from "./OrderSummary/OrderSummary";
import ProductDesignService from "../../../services/ProductDesignService";
import { IProductDesignSummaryResponse } from "../../../interfaces/Project/Response/ProductDesignSummaryResponse";
import axios from "axios";
import BackButton from "../../Profile/BackButton";
import useAppTitle from "../../../hooks/useAppTitle";
import { ENGLISH } from "../../../constants/Languages";

const ProjectSummary: React.FC = () => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;
  const navigate: any = useNavigate();
  const { openBackdrop, closeBackdrop } = useBackdrop();

  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const { setIsExportingXLSX, setIsExportingPDF } = useStoreActions((state) => state.app);
  const { currentProjectId } = useStoreState((state) => state.designs.configuration);
  const { productDesignSummary, selectedProjectsSummary, selectedProjectsWithProductsIds } = useStoreState(
    (state) => state.designs.data
  );

  const { setProductDesigns, setProductDesignSummaryDto } = useStoreActions((actions) => actions.designs);

  const [projects, setProjects] = useState<IProductDesignSummaryResponse[]>([]);

  const [coverCost, setCoverCost] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState<string>("");

  const { thunkGetProjectsSummary } = useStoreActions((actions) => actions.designs);
  const appTitle = useAppTitle()
  const currentLanguageCode = localStorage.getItem("lang") as string;

  useEffect(() => {
    setLoading(true);
    refeshScreen();
  }, [selectedProjectsSummary]);

  const refeshScreen = async () => {
    thunkGetProjectsSummary();
    var res = await ProductDesignService.getSummarDetailsByProjectId(selectedProjectsSummary);
    setProjects(res.data);
    if (selectedProjectsSummary) {
      setProductDesigns(res.data);
      refreshProductDesign();
    }
  };

  const refreshProductDesign = async () => {
    const response = await axios.all(
      selectedProjectsSummary.map(async (item) => {
        const res = await ProductDesignService.get({ projectId: item });
        return res.data;
      })
    );

    const data = response.flat();

    setProductDesignSummaryDto(data);
    const summaries = data as IProductDesignSummaryDto[];

    const orderCost = summaries
      .filter((x) => x.designStatus === EDesignStatus.Completed)
      .reduce((sum, current) => {
        return sum + current.quantity * 10 * current.numberOfCovers;
      }, 0);

    setCoverCost(orderCost);
    setLoading(false);
  };

  const handleExportProjectXLSX = async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();

    const response = await axios.all(
      selectedProjectsSummary.map(async (item) => {
        const res = await ProductDesignService.getProductDesignByProjectId(item);
        return res.data;
      })
    );

    const data = response.flat();

    openBackdrop({ message: t("design_summary.download_project.backdrop_message") });

    const currentLanguageCode = localStorage.getItem("lang") || ENGLISH;
    ProjectService.exportProject(data, currentLanguageCode)
      .then((response) => {
        const currentDate = new Date().toLocaleDateString();

        const element = document.createElement("a");
        const url = URL.createObjectURL(new Blob([response.data]));
        element.href = url;
        element.download = `${ftsTheme.name.toUpperCase()} - Current Date ${currentDate}.xlsx`;
        element.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        thunkOpenSnackBar({
          message: t(arrayBufferToString(error.response.data)),
          severity: "error",
        });
      })
      .finally(() => {
        setIsExportingXLSX(false);
      });
  };

  function arrayBufferToString(buffer) {
    let decoder = new TextDecoder("utf-8");
    return decoder.decode(buffer);
  }
  
  const handleExportProjectPDF = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();

    ProjectService.ExportPDF(selectedProjectsWithProductsIds, currentLanguageCode)
      .then((response) => {
        const currentDate = new Date().toLocaleDateString();

        const element = document.createElement("a");
        const url = URL.createObjectURL(new Blob([response.data]));
        element.href = url;
        element.download = `${ftsTheme.name.toUpperCase()} - Current Date ${currentDate}.pdf`;
        element.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        thunkOpenSnackBar({
          message: t(arrayBufferToString(error.response.data)),
          severity: "error",
        });
      })
      .finally(() => {
        setIsExportingPDF(false);
      });
  };

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      pageHeaderTextTranslationKey=""
      navbarLogoLink="/"
      navbarMainTitle={appTitle}
      navbarBackGroundColor="#fff"
      bodyBackGroundColor="#fff"
    >
      {(loading && <Spinner />) || (
        <>
          <div className="projectSummary">
            <div className="projectSummaryContainer">
              <div className="projectSummary__headerGrid">
                <div className="projectSummary__groupTitle">
                  <Box>
                    <Box>
                      <BackButton />
                    </Box>
                    <Typography
                      className="projectSummary__title"
                      variant="h5"
                    >
                      {t("projects.projectSummary")}
                    </Typography>
                  </Box>
                </div>
              </div>
              <div className="projectSummary__main">
                <div className="projectSummaryPreview__mainPreview">

                  <Grid>
                    <ProjectSummaryTable
                      refeshScreen={refeshScreen}
                      productDesignSummary={productDesignSummary}
                      setCoverCost={setCoverCost}
                      projects={projects}
                    />
                  </Grid>
                </div>

                <OrderSummary refreshProductDesign={refreshProductDesign} />
              </div>
            </div>
          </div>

          <DownloadProjectDialog
            handleExportProjectXLSX={handleExportProjectXLSX}
            handleExportProjectPDF={handleExportProjectPDF}
            ref={downloadProjectDialogRef}
          />
          <EditDialog
            ref={editDialogRef}
            name={projectName}
            projectId={currentProjectId!}
            setProjectName={setProjectName}
          />
        </>
      )}
    </ExternalLayout>
  );
};

export default ProjectSummary;
