import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { createRef, forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../../../../hooks";
import { IDialogHandle } from "../../../../../interfaces/IDialogHandle";
import "./SelectExistingProjectDialog.css";
import { Grid, Typography, useTheme } from "@mui/material";
import { IProjectSummaryDto } from "../../../../../interfaces/DTO/IProjectSummaryDto";
import { IResult } from "../../../../../interfaces/IResult";
import { IProductDesignResponse } from "../../../../../interfaces/Project/Response/ProductDesignResponse";
import { Status } from "../../../../../models/status";
import NextButton from "./NextButton";
import CloseButton from "./CloseButton";
import { FTSTheme } from "../../../../../App";
import CloseIcon from "../../../Icons/close.svg";
import { ReactSVG } from "react-svg";
import { BASIC_GLOBAL_DATE_FORMAT } from "../../../../../constants/DateFormatTypes";

interface ISelectExistingProjectDialogInputProps {}

export const selectExistingProjectDialogRef = createRef<IDialogHandle>();

const SelectExistingProjectDialog: ForwardRefRenderFunction<IDialogHandle, ISelectExistingProjectDialogInputProps> = (
  props,
  ref
) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const ftsTheme = useTheme() as FTSTheme;
  const navigate = useNavigate();
  const [projectLst, setProjectLst] = useState<IProjectSummaryDto[]>([]);
  const [selectedProject, setSelectedProject] = useState<IProjectSummaryDto | null>(null);
  const { projectsSummary } = useStoreState((state) => state.designs.data);
  const [open, setOpen] = useState(false);
  const { currentUser } = useStoreState((state) => state.auth);
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const {
    setCurrentProjectId,
    setCurrentDesignId,
    setUserDesignDataFromProductDesignResponse,
    thunkGetProductDesignDataByProjectId,
  } = useStoreActions((actions) => actions.designs);

  useEffect(() => {
    setProjectLst(
      projectsSummary.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
    );
  }, [projectsSummary]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectProject = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (selectedProject == null) {
      thunkOpenSnackBar({
        message: t("select_existing_project_dialog.helper_text.select_project.message"),
        severity: "info",
      });
      return;
    }

    const result = (await thunkGetProductDesignDataByProjectId(selectedProject.id)) as IResult<
      IProductDesignResponse[]
    >;

    if (result.status == Status.COMPLETED) {
      const prodDesign = result?.data?.find((p) => p.project.id === selectedProject?.id!);
      setCurrentProjectId(selectedProject?.id!);
      setCurrentDesignId(prodDesign?.id ?? null);

      setUserDesignDataFromProductDesignResponse(prodDesign!);
      navigate(prodDesign?.pathname ?? "/", {
        state: {
          isNewProject: false,
          isDesignSelected: prodDesign !== undefined,
        },
      });
    } else {
      thunkOpenSnackBar({
        message: t("CreateProject_ProjectNotFound_Message"),
        severity: "info",
      });
    }
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  return (
    <>
      <Dialog
        PaperProps={{
          sx: { position: "fixed", top: "24%", m: 0 },
        }}
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "480px",
            },
          },
        }}
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: 35,
            height: 35,
          }}
        >
          <ReactSVG
            onClick={() => {
              handleClose();
            }}
            src={CloseIcon}
            beforeInjection={(svg) => {
              svg.setAttribute(
                "style",
                `width: 24px; height: 24px; fill: ${ftsTheme.icon.color}; padding: 0px; margin-top:5px`
              );
            }}
          />
        </IconButton>
        <DialogContent>
          <div className="dialogcontent__main">
            <div className="dialogcontent__body">
              <Typography variant="h2">{t("openProject")}</Typography>
              <Typography
                variant="body1"
                mt={1}
              >
                {t("pleaseChooseIfYouWantToCreate")}
              </Typography>
              <div className="selectExistingProjectDialogBody__formControlContainer">
                <FormControl className="body__formControl">
                  <Typography variant="caption">{t("select_existing_project_dialog.label_name")}</Typography>
                  <Autocomplete
                    noOptionsText={t("select_existing_project_dialog.helper_text.search_project.message")}
                    value={selectedProject}
                    onChange={(event: any, newValue: IProjectSummaryDto | null) => {
                      setSelectedProject(newValue);
                    }}
                    id="combo-box-demo"
                    options={projectLst.filter((x) => x.countryId == currentUser?.countryId).sort((p1, p2) => (p1.createdAt < p2.createdAt ? 1 : -1))}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder={t("select_existing_project_dialog.input_placeholder.search_project")}
                      />
                    )}
                  />
                  <Typography>
                    <small>
                      {selectedProject != null && (
                        <span>
                          {t("select_existing_project_dialog.label.last_modified")}{" "}
                          <b>{moment(selectedProject.lastModified).format(BASIC_GLOBAL_DATE_FORMAT)}</b>
                        </span>
                      )}{" "}
                    </small>
                  </Typography>
                </FormControl>
              </div>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
            >
              <CloseButton handleClose={handleClose} />
              <NextButton
                disabled={selectedProject == null}
                handleSelectProject={handleSelectProject}
              />
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default forwardRef(SelectExistingProjectDialog);
