import { Grid } from "@mui/material";
import React, { useRef } from "react";
import { EFontPixelSize } from "../../../../../enum/EFontSize";
import { EIconPercentSize, EIconPixelSize } from "../../../../../enum/EIconSize";
import { ITileButtonProps } from "../../../../../interfaces/ITileButtonProps";
import { ITileDimension } from "../../../../../interfaces/ITileDimension";
import GridLine from "../../../Components/GridLine/GridLine";
import { isHorizontal, LimitChar } from "../limitChar";
import StandardTile from "../StandardTile";
import TileConfiguration from "../TileConfiguration";
import TilePreviewConfiguration from "../TilePreviewConfiguration";
import { FrameStyleProps } from "../../../../../interfaces/IFrameAndCoverStyle";

const TwoRockers: React.FC<ITileButtonProps> = (props) => {
  const border = props.isPreview ? "tile-border-preview" : "tile-border";

  const tileControlDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.MEDIUM,
    textareaMaxHeight: 31,
    spaceBetweenIconAndTextArea: -2,
    isFunction: false
  };

  const tileFunctionDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_FUNCTION,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.LARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.MEDIUM,
    textareaMaxHeight: 39,
    isFunction: true,
  };

  const textFieldProps = LimitChar.get(props.designCode)!;
  const coverRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={coverRef} data-designcode={props.designCode}>
        <StandardTile
          designCode={props.designCode}
          style={{ paddingTop: "10px" }}
        >
          <Grid
            className={`${border} tile-border-rocker two-rockers-margin-right two-rockers-margin-bottom printable-area-two-rockers`}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              {props.isPreview ? <span>
                <TilePreviewConfiguration
                  tileDimension={tileControlDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoRockers"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="1"
                />
              </span> :
                <span>
                  <TileConfiguration
                    textLabelSizes={
                      isHorizontal(props.insertDetail.rotate!)
                        ? textFieldProps.horizontal.twoRockers
                        : textFieldProps.vertical.twoRockers
                    }
                    tileName="twoRockers"
                    isRocker={true}
                    isHorizontal={props.isHorizontal}
                    designCode={props.designCode}
                    tileDimension={tileControlDimension}
                    insertDetail={props.insertDetail}
                    setInsertDetail={props.setInsertDetail}
                    cover={props.cover}
                    indexDetail="1"
                    coverRef={coverRef}
                    canManageRef={props.canManageRef}
                  />
                </span>}
            </Grid>
          </Grid>
          <GridLine
            coverRef={coverRef}
            styleProps={{
              left: "calc(50% - 0.5px)",
              position: "absolute",
              width: "1px",
              height: `calc(100% - ${FrameStyleProps.get(props.designCode)?.cover.padding} * 2)`,
              backgroundColor: props.insertDetail.insertColor?.secondaryPrintColor?.hexColor,
            }}
            enable={props.insertDetail.hasGridLine}
            cover={props.cover}
            indexDetail="7"
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            verticalDegrees={[0, 180]}
            defaultOrientation="vertical"
          />

          <Grid
            className={`${border} tile-border-rocker two-rockers-margin-left two-rockers-margin-bottom printable-area-two-rockers`}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            <Grid item>
              {props.isPreview ? <span>
                <TilePreviewConfiguration
                  tileDimension={tileControlDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoRockers"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="2"
                />
              </span> :
                <span>
                  <TileConfiguration
                    textLabelSizes={
                      isHorizontal(props.insertDetail.rotate!)
                        ? textFieldProps.horizontal.twoRockers
                        : textFieldProps.vertical.twoRockers
                    }
                    tileName="twoRockers"
                    isRocker={true}
                    isHorizontal={props.isHorizontal}
                    designCode={props.designCode}
                    tileDimension={tileControlDimension}
                    insertDetail={props.insertDetail}
                    setInsertDetail={props.setInsertDetail}
                    cover={props.cover}
                    indexDetail="2"
                    coverRef={coverRef}
                    canManageRef={props.canManageRef}
                  />
                </span>}
            </Grid>
          </Grid>

          <Grid
            className={`${border} tile-border-rocker two-rockers-margin-top two-rockers-margin-right two-rockers-margin-bottom printable-area-two-rockers`}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            <Grid item>
              {props.isPreview ? <span>
                <TilePreviewConfiguration
                  tileDimension={tileFunctionDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoRockers"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="3"
                />
              </span> :
                <span>
                  <TileConfiguration
                    isRocker={true}
                    textLabelSizes={
                      isHorizontal(props.insertDetail.rotate!)
                        ? textFieldProps.horizontal.twoRockers.rocker!
                        : textFieldProps.vertical.twoRockers.rocker!
                    }
                    tileName="twoRockers"
                    isHorizontal={props.isHorizontal}
                    designCode={props.designCode}
                    tileDimension={tileFunctionDimension}
                    insertDetail={props.insertDetail}
                    setInsertDetail={props.setInsertDetail}
                    cover={props.cover}
                    indexDetail="3"
                    coverRef={coverRef}
                    canManageRef={props.canManageRef}
                  />
                </span>}
            </Grid>
          </Grid>

          <Grid
            className={`${border} tile-border-rocker two-rockers-margin-left two-rockers-margin-top two-rockers-margin-bottom printable-area-two-rockers`}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            <Grid item>
              {props.isPreview ? <span>
                <TilePreviewConfiguration
                  tileDimension={tileFunctionDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoRockers"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="4"
                />
              </span> :
                <span>
                  <TileConfiguration
                    isRocker={true}
                    textLabelSizes={
                      isHorizontal(props.insertDetail.rotate!)
                        ? textFieldProps.horizontal.twoRockers.rocker!
                        : textFieldProps.vertical.twoRockers.rocker!
                    }
                    tileName="twoRockers"
                    isHorizontal={props.isHorizontal}
                    designCode={props.designCode}
                    tileDimension={tileFunctionDimension}
                    insertDetail={props.insertDetail}
                    setInsertDetail={props.setInsertDetail}
                    cover={props.cover}
                    indexDetail="4"
                    coverRef={coverRef}
                    canManageRef={props.canManageRef}
                  />
                </span>}
            </Grid>
          </Grid>

          <Grid
            className={`${border} tile-border-rocker two-rockers-margin-right two-rockers-margin-top printable-area-two-rockers`}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            <Grid item>
              {props.isPreview ? <span>
                <TilePreviewConfiguration
                  tileDimension={tileControlDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoRockers"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="5"
                />
              </span> :
                <span>
                  <TileConfiguration
                    textLabelSizes={
                      isHorizontal(props.insertDetail.rotate!)
                        ? textFieldProps.horizontal.twoRockers
                        : textFieldProps.vertical.twoRockers
                    }
                    tileName="twoRockers"
                    isRocker={true}
                    isHorizontal={props.isHorizontal}
                    designCode={props.designCode}
                    tileDimension={tileControlDimension}
                    insertDetail={props.insertDetail}
                    setInsertDetail={props.setInsertDetail}
                    cover={props.cover}
                    indexDetail="5"
                    coverRef={coverRef}
                    canManageRef={props.canManageRef}
                  />
                </span>}
            </Grid>
          </Grid>

          <Grid
            className={`${border} tile-border-rocker two-rockers-margin-left two-rockers-margin-top printable-area-two-rockers`}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            md={6}
          >
            <Grid item>
              {props.isPreview ? <span>
                <TilePreviewConfiguration
                  tileDimension={tileControlDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneButton
                      : textFieldProps.vertical.oneButton
                  }
                  tileName="twoRockers"
                  isRocker={true}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="6"
                />
              </span> :
                <span>
                  <TileConfiguration
                    textLabelSizes={
                      isHorizontal(props.insertDetail.rotate!)
                        ? textFieldProps.horizontal.twoRockers
                        : textFieldProps.vertical.twoRockers
                    }
                    tileName="twoRockers"
                    isRocker={true}
                    isHorizontal={props.isHorizontal}
                    designCode={props.designCode}
                    tileDimension={tileControlDimension}
                    insertDetail={props.insertDetail}
                    setInsertDetail={props.setInsertDetail}
                    cover={props.cover}
                    indexDetail="6"
                    coverRef={coverRef}
                    canManageRef={props.canManageRef}
                  />
                </span>}
            </Grid>
          </Grid>
        </StandardTile>
      </div>
    </>
  );
};

export default TwoRockers;
