import { EDesignStatus } from "../../../enum/EDesignStatus";
import { IInsertDetail, IInsertDetailRequest } from "../../IInsertDetail";
import { IUserDesignData } from "../../IUserDesignData";
import { IProductDesignResponse } from "../Response/ProductDesignResponse";

export interface IProductDesignUpdateRequest {
  coverImage?: string | undefined;
  comment?: string;
  favoriteDesignId?: string;
  id: string;
  name?: string;
  isArchived: boolean;
  designId: string;
  projectId: string;
  frameFoldId: string;
  frameColorId: string;
  isHorizontal?: boolean;
  pathname: string;
  designStatus?: EDesignStatus;
  insertDetails: IInsertDetailRequest[];
  quantity: number;
  room: string;
  location: string;
  previewUrl?: string;
}

export const userDataToProductDesignRequest = (payload: IUserDesignData) => {

  const request: IProductDesignUpdateRequest = {
    coverImage: payload.userCustomDesign.coverImage,
    comment: payload.userCustomDesign.comment,
    favoriteDesignId: payload.userCustomDesign.favoriteDesignId,
    id: payload.userCustomDesign.id!,
    name: payload.userCustomDesign.name!,
    isArchived: payload.userCustomDesign.isArchived!,
    designId: payload.userCustomDesign.designId!,
    projectId: payload.userCustomDesign.projectId!,
    frameFoldId: payload.userCustomDesign.frameFold?.id!,
    frameColorId: payload.userCustomDesign.frameColor?.id!,
    isHorizontal: payload.userCustomDesign.isHorizontal!,
    pathname: payload.userCustomDesign.pathname!,
    designStatus: payload.userCustomDesign.designStatus!,
    insertDetails: payload.userCustomDesign.insertDetails.map(insertDetailToRequest),
    quantity: payload.userCustomDesign.quantity!,
    room: payload.userCustomDesign.room || "",
    location: payload.userCustomDesign.location || ""
  };
  return request;
};

export const getProductDesignUpdateRequest = (payload: IProductDesignResponse) => {
  const request: IProductDesignUpdateRequest = {
    id: payload.id!,
    name: payload.name!,
    isArchived: payload.isArchived!,
    designId: payload.designId!,
    projectId: payload.project.id!,
    frameFoldId: payload.frameFold?.id!,
    frameColorId: payload.frameColor?.id!,
    isHorizontal: payload.isHorizontal!,
    pathname: payload.pathname!,
    designStatus: payload.designStatus! || EDesignStatus.Draft,
    insertDetails: payload.insertDetails.map(insertDetailToRequest),
    quantity: payload.quantity!,
    room: payload.room || "",
    location: payload.location || "",
    previewUrl: payload.imagePreviewUrl!
  };
  return request;
}

const insertDetailToRequest = (detail: IInsertDetail) => {
  if (detail == null) return detail;

  const insertDetailRequest: IInsertDetailRequest = {
    id: detail.id,
    insertTypeId: detail.insertType?.id,
    insertColorId: detail.insertColor?.id,
    order: detail.order!,
    coverDesignId: detail.coverDesignId,
    rotate: detail.rotate,
    hasGridLine: detail.hasGridLine,
    isFlipped: detail.isFlipped,
    items: detail.items.filter((item) => !item.gridLine || detail.hasGridLine),
  };
  return insertDetailRequest;
};
